import axios from "axios";
import React, { useState, useEffect, useMemo,useRef } from "react";
import DataTable, { Alignment } from "react-data-table-component";
import { gregorianToJalali } from "shamsi-date-converter";
import Source from "../../Source";
import AddItem from "./forms/AddItem";
import Swal from "sweetalert2";
import moment from "moment-jalaali";
import Trash from "../../assets/icon/trash.png";
import pencil from "../../assets/icon/pencil.png";
import Edititem from "./forms/Edititem";


export default function Items() {
  const [records,setRecords]=useState([]);
  const [AddItemModal,setAddItemModal]=useState(false);
  const [EditItemModal,setEditItemModal]=useState(false);
  const [Itemtype,setItemtype]=useState([]);
  const getItemtype=()=>{
    axios.get(Source.getAddress()+'/api/itemtype', {headers: {
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    }},)
    .then((res)=>{
      setItemtype(res.data);
    }).catch((err)=>{
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 1000,
      });
    });
  }
  const delete_item= async(id)=>{
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
  
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if(result.isConfirmed){
      axios.delete(Source.getAddress()+'/api/item/'+id,{ headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`, // Add the access token here
        // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
      },})
      .then((res)=>{

        Swal.fire({
          position: "center",
          icon: "success",
          title: "Item record successfully deleted !",
          showConfirmButton: false,
          timer: 600,
        });
        setItemtype((prevRecords) =>
          prevRecords.filter((record) => record.id !== id)
        );
      }).catch((err)=>{
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Item record not deleted",
          showConfirmButton: false,
          timer: 600,
        });
      })
    }else if(result.dismiss === Swal.DismissReason.cancel){
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Your Item record is safe :)",
        showConfirmButton: false,
        timer: 600,
      });
    }
    
  }
  const date = (d) => {

    const date = new Date(d);
  
    let formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    let aa = ampm;
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    if (localStorage.getItem('date') === "Persian") {
      moment.locale('fa'); // تنظیم لوکال به فارسی
      aa = hours >= 12 ? "ب.ظ" : "ق.ظ";
      formattedDate = moment(d).format('jYYYY-jMM-jDD');
    } else {
      moment.locale('en'); // تنظیم لوکال به انگلیسی
    }
  
    return `${formattedDate} ${formattedHours}:${formattedMinutes} ${aa}`;
  };
  useEffect(()=>{
    const fetchItem = async () => {
      // const token = ;
      const fetchWithToken = async () => {
        try {
          const response = await axios.get(Source.getAddress()+'/api/item', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('access')}`,
            },
          });
          setRecords(response.data);
        } catch (error) {
          console.log(error);
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem('refresh');
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(Source.getAddress() + '/api/token/refresh', {
                refresh: refreshToken,
              });
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem('access', newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              console.error('Error refreshing access token:', refreshError);
            }
          } else {
            console.error('Error fetching accounts:', error);
          }
        }
      };

      await fetchWithToken();
    };
    fetchItem();
  },[])
  const [selectedOption, setSelectedOption] = useState();
  const [Item,setItem] = useState({
    id: 0,
    name: "",
    type_id: 0,
    user_id: 0,
    isdelete: 0,
    description: "",
    date_creation: "",
    serial_number: "",
    created_at: "",
    updated_at: "",
    type: {
       },
    user: {
    }
  });
  const Edit_item=(row)=>{
    getItemtype();
  setItem(row);
  setAddItemModal(false);
  setEditItemModal(true);
  setSelectedOption(row.type);
  }
  const columns = [
    {  name: (
      <strong
        style={{ minWidth: "50px", maxWidth: "50px", width: "240px", textAlign:"left"}}
      >
        ID
      </strong>
    ), selector: (row) => row.id, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    
    {
      name: (
        <strong
          style={{ minWidth: "100px", maxWidth: "100px", width: "100px", textAlign:"left"}}
        >
          Photo
        </strong>
      ),
      cell: (row) => (
        <img
          src={row.type.picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {  name: (
      <strong
        style={{ minWidth: "200px", maxWidth: "240px", width: "240px", textAlign:"left"}}
      >
        Item Type
      </strong>
    ), selector: (row) => row.type.name, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    }
    ,
    {  name: (
      <strong
        style={{ minWidth: "10px", maxWidth: "20px", width: "20px", textAlign:"left"}}
      >
        Item
      </strong>
    ), selector: (row) => row.name, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    }
  ,
  { name: (
    <strong
      style={{ minWidth: "200px", maxWidth: "240px", width: "240px", textAlign:"left"}}
    >
      Serial Number
    </strong>
  ), selector: (row) => row.serial_number, sortable: true 
    ,style: {
      Alignment:"center",
      width: "1px",
      minWidth: "1px",
    },
  }
,
{
  name: (
    <strong
      style={{ minWidth: "200px", maxWidth: "240px", width: "240px" }}
    >
      Date Created
    </strong>
  ),
  selector: (row) => date(row.date_creation),
  style: {
    minWidth: "170px!important", // Adjust this value as needed
    maxWidth: "170px", // Adjust this value as needed
    margin:'0px'
    // Width: '600px',    // Set a specific width
  },
  // sortable: true,
},{ name: (<strong>Description</strong>), selector: (row) => row.description, sortable: true },
    { name: (<strong>Added by</strong>), selector: (row) => row.user.name , sortable: true },
    { name: (<strong
      style={{
        textAlign: "center",
        backgroundColor: "tranceparent",
        width: "100%",
      }}
    >
      Edit
    </strong>),selector: (row) => (
      <button
        onClick={() => {
          Edit_item(row);
          // setEdit(row);
          // setUpdate(true);
          // seTtitle('Edit Type Money');
        }}
        style={{
          border: "none",
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <img
          height={"20%"}
          width={"20%"}
          src={pencil}
          style={{ backgroundColor: "tranceparent" }}
        />
      </button>
    ) },
    { name: (<strong
      style={{
        textAlign: "center",
        backgroundColor: "tranceparent",
        width: "100%",
      }}
    >
      Delete
    </strong>),   selector: (row) => (
      <button
        onClick={() => {
          delete_item(row.id);
          // setEdit(row);
          // setUpdate(true);
          // seTtitle('Edit Type Money');
        }}
        style={{
          border: "none",
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <img
          height={"15%"}
          width={"15%"}
          src={Trash}
          style={{ backgroundColor: "tranceparent" }}
        />
      </button>
    ) },
  ];
  
  return (
    <div
      className={`w-100 ${"iransans"}`}
    >
      <div className="m-auto mt-5 m-5" style={{ height: "100%",width:"90%" }}>
      <button
          type="submit"
          className="btn btn-info mb-1 p-1"
          style={{ width: "100px" }}
          onClick={() => {
            getItemtype();
            setAddItemModal(true);
            setEditItemModal(false);
          }}
        >
          Add
        </button>
        <div className="bg-light d-flex justify-content-lg-between"
          style={{borderTop: "5px solid #4a5cf2"}}
        
        >
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
          <h2 className="m-2 fw-bold mb-4">Items</h2>
            <select
              id=""
              name="gender"
            //   onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
            //   value={select_user}
            >
              <option value={'all users'} selected>All Users</option>
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            // onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>
        <DataTable
          columns={columns}
          data={records}
          striped
          responsive
          highlightOnHover
          pagination
          // onRowClicked={handleRowClick}
          // title="Customers"
          // noHeader
          // noTableHead
          customStyles={{
            headCells: {
              style: {
                display: 'flex',
                minWidth: '100px', // تنظیم اندازه عنوان‌ها
                maxWidth: '100px',
                width: '10px',
                margin: '0px',
              },
            },
            cells: {
              style: {
                minWidth: '100px', // تنظیم اندازه داده‌ها
                maxWidth: '150px',
                width: '150px',
              },
            },
          }}
        />
          <AddItem
          
          records={records}
          setRecords={setRecords}
          Itemtype={Itemtype}
          AddItemModal={AddItemModal}
          close={()=>setAddItemModal(false)}
          />
          <Edititem
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          records={records}
          setRecords={setRecords}
          AddItem={Item}
          setAddItem={setItem}
          Itemtype={Itemtype}
          AddItemModal={EditItemModal}
          close={()=>setEditItemModal(false)}
          />
      </div>
    </div>
  );
}