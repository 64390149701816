import axios from "axios";
import React, { useState, useEffect, useMemo,useRef } from "react";
import DataTable from "react-data-table-component";
import { gregorianToJalali } from "shamsi-date-converter";
import Source from "../../Source";
import AddItemType from "./forms/AddItemType";
import * as XLSX from "xlsx"; // Import the xlsx library
import AddStock from "../../assets/icon/AddStock.png";
import Trash from "../../assets/icon/trash.png";
import pencil from "../../assets/icon/pencil.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";

export default function ItemType() {
  const [openadd,setopenadd]=useState(false);
  const [records,setRecords]=useState();
  const inputRef=useRef();
  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(records); // Convert JSON to sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Item Types");
    XLSX.writeFile(workbook, "ItemTypes.xlsx"); // File name
  };
  useEffect(()=>{
    const fetchItemtype = async () => {
      const token = localStorage.getItem('access');
      if (!token) return;
      const fetchWithToken = async (token) => {
        try {
          const response = await axios.get(Source.getAddress()+'/api/itemtype', {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });
          setRecords(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem('refresh');
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(Source.getAddress() + '/api/token/refresh', {
                refresh: refreshToken,
              });
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem('access', newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              console.error('Error refreshing access token:', refreshError);
            }
          } else {
            console.error('Error fetching accounts:', error);
          }
        }
      };

      await fetchWithToken(token);
    };
    fetchItemtype();
  },[]);
  const delete_itemtype = async (id)=>{
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
  
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if(result.isConfirmed){
    
    axios.delete(Source.getAddress()+'/api/itemtype/'+id,{
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      }
    })
    .then((res)=>{
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Item record successfully deleted !",
        showConfirmButton: false,
        timer: 600,
      });
      setRecords((prevRecords) =>
        prevRecords.filter((record) => record.id !== id)
      );
    }).catch((err)=>{
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Item record not deleted",
        showConfirmButton: false,
        timer: 600,
      });
    })
    }
    else if(result.dismiss === Swal.DismissReason.cancel){
      Swal.fire({
        position: "center",
        icon: "error",
        text: "Your Item record is safe :)",
        showConfirmButton: false,
        timer: 600,
      });
    }
  }
  const columns = [
    { name: "Id", selector: (row) => row.id, sortable: true 
      ,style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.picture===null ? AddStock : row.picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    { name: "Name", selector: (row) => row.name, sortable: true },
    { name: "Mesurment", selector: (row) => row.measuring, sortable: true },
    { name: (<strong
      style={{
        textAlign: "center",
        backgroundColor: "tranceparent",
        width: "100%",
      }}
    >
      Edit
    </strong>),selector: (row) => (
      <button
        onClick={() => {
          // Edit_item(row);
          // setEdit(row);
          // setUpdate(true);
          // seTtitle('Edit Type Money');
        }}
        style={{
          border: "none",
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <img
          height={"20%"}
          width={"20%"}
          src={pencil}
          style={{ backgroundColor: "tranceparent" }}
        />
      </button>
    ) },{ name: (<strong
      style={{
        textAlign: "center",
        backgroundColor: "tranceparent",
        width: "100%",
      }}
    >
      Delete
    </strong>),   selector: (row) => (
      <button
        onClick={() => {
          delete_itemtype(row.id);
          // setEdit(row);
          // setUpdate(true);
          // seTtitle('Edit Type Money');
        }}
        style={{
          border: "none",
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <img
          height={"15%"}
          width={"15%"}
          src={Trash}
          style={{ backgroundColor: "tranceparent" }}
        />
      </button>
    ) },
  ];
  const handleAdd=()=>{
    inputRef.current.select();
    setopenadd(true);
  }
  return (
    <div
      className={`w-100 ${"iransans"}`}
    >
      <div className="w-50 m-auto mt-5 m-5" style={{ height: "100%" }}>
      <button
        onClick={handleAdd}
        type="submit"
        className="btn btn-info mb-1 p-1 text-white"
        style={{ width: "100px" }}
      >
        Add
      </button>
        <div className="bg-light d-flex justify-content-lg-between"
          style={{borderTop: "5px solid #4a5cf2"}}
        
        >
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
          <h2 className="m-2 fw-bold mb-4">Item type</h2>
            <select
              id=""
              name="gender"
            //   onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
            //   value={select_user}
            >
              <option value={'all users'} selected>All Users</option>
              {/* {user.map((item) =>(
              <option value={item.name} selected>{item.name}</option>
              ))} */}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            // onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
          // title="Customers"
          columns={columns}
          data={records}
          // onRowClicked={handleRowClick}
          striped
          responsive
          highlightOnHover
          pagination
        />
      </div>
      <AddItemType
       setRecords={setRecords} records={records} open={openadd} close={()=>{setopenadd(false)}} inputRef={inputRef}
      />
    </div>
  );
}