import React, { useEffect, useRef, useState } from "react";
import Source from "../Source";
import axios from "axios";
import Swal from "sweetalert2";
import Profile from "../assets/icon/profile.png";
import IdNational from "../assets/icon/national_id.png";

export default function AddAccount({
  close,
  addAccountModal,
  records,
  setRecords,
  accounts,
  inputRef,
  // setLoading
}) {
  const [account, setAccount] = useState({
    profile_picture: Profile,
    national_id_picture: IdNational,
  });

  const [profile_picture, setProfilePicture] = useState(null);
  const [national_id_number, setNationalIdNumber] = useState("");
  const [national_id_picture, setNationalIdPicture] = useState(null);
  const [addresss, setAddresss] = useState("");
  const [whatsup_number, setWhatsupNumber] = useState("");
  const [name, setName] = useState("");
  const [father_name, setFatherName] = useState("");
  const [phone_number, setPhoneNumber] = useState("");

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      if (event.target.name === "profile_picture") {
        setProfilePicture(selectedImage);
        setAccount((prevState) => ({
          ...prevState,
          profile_picture: reader.result,
        }));
      } else if (event.target.name === "national_id_picture") {
        setNationalIdPicture(selectedImage);
        setAccount((prevState) => ({
          ...prevState,
          national_id_picture: reader.result,
        }));
      }
    };
    reader.readAsDataURL(selectedImage);
  };

  const Edit_Account = () => {
    // setLoading(true);
    const dateCreate = new Date();
    const add = {
      id: 0,
      isdelete: "False",
      user_name: localStorage.getItem("userToken"),
      profile_picture: account.profile_picture,
      national_id_number: national_id_number,
      national_id_picture: account.national_id_picture,
      addresss: addresss,
      whatsup_number: whatsup_number,
      name: name,
      father_name: father_name,
      phone_number: phone_number,
      date_created: dateCreate.toISOString(),
      user: localStorage.getItem('userTokenid'),
    };
    console.log(add.date_created);
    // axios.get(Source.getAddress())
    const uploadData = new FormData();

    // uploadData.append("user_name", localStorage.getItem("userToken"));
    uploadData.append("isdelete", 0);
    // Uncomment the next line if you need to send the user ID
    uploadData.append("user_id", localStorage.getItem("userTokenid"));

    if (profile_picture) {
      uploadData.append("profile_picture", profile_picture);
    }

    uploadData.append("national_id_number", national_id_number);

    if (national_id_picture) {
      uploadData.append("national_id_picture", national_id_picture);
    }

    uploadData.append("addresss", addresss);
    uploadData.append("whatsup_number", whatsup_number);
    uploadData.append("name", name);
    uploadData.append("father_name", father_name);
    uploadData.append("phone_number", phone_number);

    if (name) {
      axios
      .post(Source.getAddress() + "/api/customers", uploadData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
          'Content-Type': 'multipart/form-data', // multipart for file uploads
        },
      })
    //   fetch(Source.getAddress() + "/api/customers/", {
    //     method: "POST",
    //     headers: {
    // Authorization: `Bearer ${localStorage.getItem("access")}`,
    // 'Content-Type': 'multipart/form-data', // multipart for file uploads
    //     },
    //     body: uploadData, // Body should be FormData
    //   })
        // .then((res) => {
        //   if (!res.ok) {
        //     throw new Error("Network response was not ok");
        //   }
        //   return res.json();
        // })
        .then((response) => {
          console.log(response);
          add.id = response.data.id;
          setRecords([add, ...records]);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Customer added successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          close();
          setAccount({
            profile_picture: Profile,
            national_id_picture: IdNational,
          });
          setProfilePicture(null);
          setNationalIdNumber("");
          setNationalIdPicture(null);
          setAddresss("");
          setWhatsupNumber("");
          setName("");
          setFatherName("");
          setPhoneNumber("");
        })
        .catch((error) => {
          console.error("Error:", error); // Log the error for debugging
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "You must enter the customer name!",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };
  // const Edit_Account = () => {
  //   // Create a FormData object for the multipart/form-data request
  //   const formData = new FormData();
  
  //   // Add form fields to FormData
  //   formData.append("isdelete", 0);
  //   formData.append("user_id", "1");
  //   formData.append("name", "sajad");
  //   formData.append("date_created", "2000-10-10");
  
  //   // If a profile picture is provided, append it to the FormData
  //   if (profile_picture) {
  //     formData.append("profile_picture", profile_picture); // Make sure profile_picture is a File object
  //   }
  
  //   // Send the formData with axios
    // axios
    //   .post(Source.getAddress() + "/api/customers", formData, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("access")}`,
    //       'Content-Type': 'multipart/form-data', // multipart for file uploads
    //     },
    //   })
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((err) => {
  //       console.log(err.response.data); // Log any errors
  //     });
  // };
  
  
  const buttonRef = useRef(null);
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (buttonRef.current && addAccountModal) {
        buttonRef.current.click();
      }
    }
  };

  return (
    <div
      className={`container rounded-5 popup customer ${addAccountModal ? "show" : ""
        }`}
      style={{
        maxWidth: "70%",
        overflowX: "auto",
        overflowY: "auto",
        height: "80vh",
        backgroundColor: "#f8f9fa",
        padding: "20px",
      }}
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close p-3 m-1 mt-0 hover_btn"
          onClick={close}
          aria-label="Close"
        ></button>
      </div>
      <div className="p-1 rounded-5">
        <div className="row">
          <h1
            className="text-center rounded p-4 text-light"
            style={{ backgroundColor: "var(--bs-info)", width: "100%" }}
          >
            Add Account
          </h1>
          <div className="col-12 col-md-5 d-flex flex-column align-items-center mt-5">
            <div>
              <img
                className="td_img rounded"
                src={account.profile_picture}
                style={{ height: "150px", width: "250px", objectFit: "cover" }}
                alt="Profile"
              />
            </div>
            <div className="mt-3" style={{ width: "100%" }}>
              <label htmlFor="profile_picture" style={{ fontWeight: "bold" }}>
                Profile Picture
              </label>
              <input
                onKeyDown={handleKeyDown}
                type="file"
                accept="image/*"
                name="profile_picture"
                onChange={handleImageChange}
                className="form-control"
                style={{ width: "100%" }}
              />
            </div>

            <div className="mt-3">
              <img
                className="td_img rounded"
                src={account.national_id_picture}
                style={{ height: "150px", width: "250px", objectFit: "cover" }}
                alt="National ID"
              />
            </div>
            <div className="mt-3" style={{ width: "100%" }}>
              <label
                htmlFor="national_id_picture"
                style={{ fontWeight: "bold" }}>
                National Id Picture
              </label>
              <input
                onKeyDown={handleKeyDown}
                type="file"
                accept="image/*"
                name="national_id_picture"
                onChange={handleImageChange}
                className="form-control"
                style={{ width: "100%" }}
              />
            </div>
          </div>

          <div className="col-12 col-md-7 mt-5">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Name
            </label>
            <input
              ref={inputRef}
              onKeyDown={handleKeyDown}
              onChange={(e) => setName(e.target.value)}
              value={name}
              type="text"
              name="name"
              placeholder="Name"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="father_name" style={{ fontWeight: "bold" }}>
              Father Name
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => setFatherName(e.target.value)}
              value={father_name}
              type="text"
              name="father_name"
              placeholder="Father Name"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="national_id_number" style={{ fontWeight: "bold" }}>
              National Id Number
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => setNationalIdNumber(e.target.value)}
              value={national_id_number}
              type="text"
              name="national_id_number"
              placeholder="National Id Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="phone_number" style={{ fontWeight: "bold" }}>
              Phone Number
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phone_number}
              type="text"
              name="phone_number"
              maxLength={10}
              placeholder="Phone Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="whatsup_number" style={{ fontWeight: "bold" }}>
              Whatsup Number
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => setWhatsupNumber(e.target.value)}
              value={whatsup_number}
              type="text"
              name="whatsup_number"
              maxLength={10}
              placeholder="Whatsup Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <label htmlFor="addresss" style={{ fontWeight: "bold" }}>
              Address
            </label>
            <input
              onKeyDown={handleKeyDown}
              onChange={(e) => setAddresss(e.target.value)}
              value={addresss}
              type="text"
              name="addresss"
              placeholder="Address"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
          </div>
          <div className="col-12 d-flex justify-content-center mt-2">
            <button
              type="button"
              className="text-center btn btn-danger text-light me-2"
              style={{ width: "150px" }}
              onClick={close}
            >
              Cancel
            </button>
            <button
              ref={buttonRef}
              type="button"
              className="text-center btn btn-success text-light ms-2"
              style={{ width: "150px" }}
              onClick={Edit_Account}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
