import React, { useState, useEffect, useMemo } from "react";
import Source from "./Source";
import Sidebar from "./Sidebar.js";
import ItemType from "./pages/Items/ItemType.js";
import Items from "./pages/Items/Items.js";
import Stock from "./pages/Items/Stock.js";
import Purchase from "./pages/Items/Purchase.js";
import AllTransactions from "./pages/Transactions/AllTransactions.js";
import Sell from "./pages/Items/Sell.js";
import Dashboard from "./pages/Dashboard";
import Account from "./pages/Account";
import Money from "./pages/Money";
import Setting from "./pages/Setting.js";
import Withdraw from "./pages/Withdraw.js";
import Deposite from "./pages/Deposite.js";
import ReportCustomers from "./pages/Report/ReportCustomers.js";
import Cach from "./pages/Cach.js";
import DraftTransaction from "./pages/Draft/DraftTransaction.js";
import Manage from "./pages/Manage";
import Profile from "./pages/Profile";
import Accountp from "./assets/icon/accountp.png";
import axios from "axios";
import Swal from "sweetalert2";
import { useCollapse } from "react-collapsed";
// import useCollapse from "./usecollapse.js";
import Setting_pic from "./assets/icon/settings.png";
import Customer_pic from "./assets/icon/customers_pic.png";
import Deposite_pic from "./assets/icon/deposite.png";
import Withdraw_pic from "./assets/icon/withdraw.png";
import ReportPic from "./assets/icon/report.png";
import DraftCustomers from "./pages/Draft/DraftCustomers.js";
import DraftAccount from "./pages/Draft/DraftAccount.js";
// import DraftTransaction from "./assets/icon/DraftTransaction.png";
import DraftMoney from "./pages/Draft/DraftMoney.js";
import Cash_pic from "./assets/icon/money.png";
import Down from "./assets/icon/down.png";
import Menu from "./assets/icon/menuu.png";
import Business from "./assets/icon/Business.png";
import Logout from "./assets/icon/logout.png";
import Manager from "./assets/icon/manager.png";
import MoneyPic from "./assets/icon/money.png";
import DashboardPic from "./assets/icon/dashboard.png";
import Wellcom from "./pages/forms/Wellcom";
import AccountPic from "./assets/icon/accounts.png";
import Customers from "./pages/Customers.js";
import Draft_pic from "./assets/icon/draft.png";
import Change from "./assets/icon/changepass.png";
import expand from "./assets/icon/down.png";
import alldraft from "./assets/icon/alldraft.png";
import ThirdDeptDtable from "./pages/ThirdDeptDtable.js";
import "../src/App.css";
import Loading from "./Loading.js";
import { MyProvider } from './pages/constext/UseContext.js';
// import Setting from "./pages/Setting.js";

function App1({
  setCorrect,
  setError,
  loguser,
  setLoguser,
  type,
  Collapse,
  setCollapse,
  username,
  password,
  setting,
}) {
  // useEffect(() => {
  //   const refreshAccessToken = async () => {
  //     const refreshToken = localStorage.getItem('refresh');
  //     if (!refreshToken) {
  //       console.error("No refresh token found");
  //       return;
  //     }

  //     try {
  //       const response = await axios.post(Source.getAddress() + '/api/token/refresh/', {
  //         refresh: refreshToken,
  //       });
  //       const newAccessToken = response.data.access;
  //       localStorage.setItem('access', newAccessToken);
  //       console.log(newAccessToken);
  //     } catch (error) {
  //       console.error("Error refreshing access token:", error);
  //     }
  //   };

  //   // Check if access token is present and valid before making requests
  //   const token = localStorage.getItem('access');
  //   if (!token) {
  //     refreshAccessToken();
  //   } else {
  //     // Here you can also add a function to verify the token validity, if needed
  //   }
  // }, []);

  // console.log(setting);
  const [money, setMoney] = useState([]);
  const [date, setdate] = useState("Hejiri Date");
  // console.log(password);

  const [deposite, setdeposite] = useState([]);
  const [withdraw, setwithdraw] = useState([]);
  const [alltransactions,setalltransactions] = useState([]);
  const [draft, setdraft] = useState([]);
  const [Cash, setCash] = useState([]);
  const [user, setuser] = useState([]);
  const [settings, setsettings] = useState([{ date: "", language: "" }]);
  // for cach report is
  const [totals, setTotals] = useState({});
  useEffect(()=>{
    const fetchTotalRecords = async () => {
      const newTotals = {};
      let moneyid;
      for (const record of money) {
        try {
          const res = await axios.get(
            Source.getAddress() + "/api/reports/sum_by_money_type",
            {
              params: {
                moneytype: record.id,
              },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('access')}`
                }
            }
          );
          moneyid = record.id;
          newTotals[record.id] = res.data.total_amount;
          console.log(newTotals);
        } catch (err) {
          console.error("Error getting report:", err);
        }
      }
      setTotals(newTotals);
    }; 
    fetchTotalRecords(); 
  },[money])
  

  const [loading, setLoading] = useState(false);



 

  const [belance, setbelance] = useState([]);
 
  
  const [accounts, setAccounts] = useState([]);


  const [users, setUsers] = useState([]);

  const handleLogout = () => {
    // localStorage.removeItem('sidebar');
    localStorage.removeItem("userToken");
    localStorage.removeItem("access");
    localStorage.removeItem("userTokenname");
    localStorage.removeItem("userTokenid");
    localStorage.removeItem("userToken");
    localStorage.clear();
    setError(false);
    setCorrect(false);
  };
  const [records, setRecords] = useState(accounts);
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };


  const [typemoney, settypemoney] = useState();
  let Component;
  switch (window.location.pathname) {
    case "/account":
      Component = (
        <Account
          loading={loading}
          setLoading={setLoading}
          accounts={belance}
          money={money}
          customers={accounts}
          setAccounts={setAccounts}
          user={user}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
    case "/withdraw":
      Component = (
        <Withdraw
        loading={loading}
          setLoading={setLoading}
        money={money}
          accounts={accounts}
          setAccounts={setAccounts}
          withdraw={withdraw}
          belance={belance}
          setbelance={setbelance}
          user={user}
          Cash={Cash}
          setCash={setCash}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
    case "/deposite":
      Component = (
        <Deposite
        loading={loading}
          setLoading={setLoading}
        money={money}
          accounts={accounts}
          setAccounts={setAccounts}
          deposite={deposite}
          setbelance={setbelance}
          belance={belance}
          user={user}
          Cash={Cash}
          setCash={setCash}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
      case "/alltransactions":
      Component = (
        <AllTransactions
        loading={loading}
          setLoading={setLoading}
          money={money}
          accounts={accounts}
          setAccounts={setAccounts}
          deposite={alltransactions}
          setbelance={setbelance}
          belance={belance}
          user={user}
          Cash={Cash}
          setCash={setCash}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
    case "/cach/report":
      Component = (
        <ThirdDeptDtable
          money={money}
          setMoney={setMoney}
          loading={loading}
          setLoading={setLoading}
          typemoney={typemoney}
          settypemoney={settypemoney}
          deposite={deposite}
          setbelance={setbelance}
          belance={belance}
          user={user}
          Cash={Cash}
          setCash={setCash}
          settings={settings}
          setsettings={setsettings}
          accounts={accounts}
          setAccounts={setAccounts}
        />
      );
      break;
    case "/drafttransactions":
      Component = (
        <DraftTransaction
        loading={loading}
          setLoading={setLoading}
          withdraw={draft}
          belance={belance}
          Cash={Cash}
          user={user}
          setCash={setCash}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
    case "/dashboard":
      Component = <Dashboard />;
      break;
    case "/money_type":
      Component = (
        <Money
        loading={loading}
          setLoading={setLoading}
          money={money}
          setMoney={setMoney}
          belance={belance}
          user={user}
          settings={settings}
        />
      );
      break;
    case "/setting":
      Component = (
        <Setting
        loading={loading}
          setLoading={setLoading}
          money={money}
          belance={belance}
          setMoney={setMoney}
          user={user}
          date1={date}
          setdate={setdate}
          username={username}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
    case "/profile":
      Component = (
        <Profile
        loading={loading}
          setLoading={setLoading}
          loguser={loguser}
          setLoguser={setLoguser}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
    case "/cash":
      Component = (
        <Cach
          loading={loading}
          setLoading={setLoading}
          // fetchTotalRecords={fetchTotalRecords}
          totals={totals}
          setTotals={setTotals}
          money={money}
          setMoney1={setMoney}
          setLoguser={setLoguser}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
    // case "/":
    //   Component = <Wellcom settings={settings} setsettings={setsettings} />;
    //   break;
    case "/manage":
      if (localStorage.getItem("userTokenname") === "admin") {
        Component = (
          <Manage
          loading={loading}
          setLoading={setLoading}
            users={users}
            // deleteItem={deleteItem}
            setUsers={setUsers}
            settings={settings}
            setsettings={setsettings}
          />
        );
      }
      break;
    case "/customers":
      // if (type === "admin") {
      Component = (
        <Customers
        money={money}
        loading={loading}
          setLoading={setLoading}
          accounts={accounts}
          setAccounts={setAccounts}
          date={date}
          user={user}
          settings={settings}
          setsettings={setsettings}
          belance={belance}
          setbelance={setbelance}
        />
      );
    // }
    break;
    case "/draftcustomer":
      Component = (
        <DraftCustomers
        money={money}
        loading={loading}
          setLoading={setLoading}
          accounts={accounts}
          setAccounts={setAccounts}
          date={date}
          user={user}
          settings={settings}
          setsettings={setsettings}
          belance={belance}
          setbelance={setbelance}
        />
      );
    break;
    case "/draftmoney":
      Component = (
        <DraftMoney
        loading={loading}
          setLoading={setLoading}
          money={money}
          setMoney={setMoney}
          belance={belance}
          user={user}
          settings={settings}
        />
      );
      break;
      case "/draftaccount":
      Component = (
        <DraftAccount
          loading={loading}
          setLoading={setLoading}
          accounts={belance}
          money={money}
          customers={accounts}
          setAccounts={setAccounts}
          user={user}
          settings={settings}
          setsettings={setsettings}
        />
      );
      break;
      case "/customerreport":
          Component = (
            <ReportCustomers
            Customers={accounts}
            // settings={settings}
              />
          );
          break;
      case "/itemtype":
        Component = (
          <ItemType
            />
        );
        break;
        case "/items":
          Component = (
            <Items
              />
          );
          break;
          case "/stock":
            Component = (
              <Stock
                />
            );
            break;
            case "/purchase":
          Component = (
            <Purchase
              />
          );
          break;
          case "/sell":
          Component = (
            <Sell
              />
          );
          break;
  }

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  const columns = useMemo(() => {
    if (isDesktop) {
      setIsToggled(false);
    }
    else{
      setIsToggled(true);
    }
    return ;
  }, [isDesktop]);
  const styles = {
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      width:'100vw',
      position: 'fixed',
      // top: -60rem;
      // left: 50%
      /* opacity: 0.1; */
      transform: 'translateX(-50%)',
      width: '300px',
      padding: '20px',
      // background-color: white;
      boxShadow:' 0 5px 15px rgba(0, 0, 0, 0.3)',
      transition: 'top 0.5s ease-in',
      IDBIndex: '1000',
    },
  };
  // useEffect(()=>{
  //   axios.get(Source.getAddress()+'/api/logs/', {
  //     headers: {
  //       'Authorization': `Bearer ${localStorage.getItem('access')}`,
  //     },
  //   }).then((res)=>{
  //   }).catch((err)=>{
  //   })
  // },[]);
  // useEffect(() => {
  //   const fetchAccounts = async () => {
  //     const token = localStorage.getItem('access');
  //     if (!token) return;
  //     const fetchWithToken = async (token) => {
  //       try {
  //         const response = await axios.get(Source.getAddress() + '/api/customers/', {
  //           headers: {
  //             'Authorization': `Bearer ${token}`,
  //           },params: {
  //             delete: 'False',
  //           },
  //         });
  //         setAccounts(response.data);
  //       } catch (error) {
  //         if (error.response && error.response.status === 401) {
  //           const refreshToken = localStorage.getItem('refresh');
  //           if (!refreshToken) return;

  //           try {
  //             const refreshResponse = await axios.post(Source.getAddress() + '/api/token/refresh/', {
  //               refresh: refreshToken,
  //             });
  //             const newAccessToken = refreshResponse.data.access;
  //             localStorage.setItem('access', newAccessToken);
  //             await fetchWithToken(newAccessToken);
  //           } catch (refreshError) {
  //             console.error('Error refreshing access token:', refreshError);
  //           }
  //         } else {
  //           console.error('Error fetching accounts:', error);
  //         }
  //       }
  //     };

  //     await fetchWithToken(token);
  //   };

  //   const fetchBelance = async () => {
  //     try {
  //       const token = localStorage.getItem('access');
  //       if (token) {
  //         const res = await axios.get(Source.getAddress() + '/api/belance/', {
  //           params: {
  //             delete: 'False',
  //           },
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         });
  //         setbelance(res.data);
  //       }
  //     } catch (err) {
  //       console.error('Error getting Belance:', err);
  //     }
  //   };

  //   const fetchData = async () => {
  //     const token = localStorage.getItem('access');
  //     if (!token) return;

      // const headers = {
      //   Authorization: `Bearer ${token}`,
      // };

  //     try {
  //       const userResponse = await axios.get(Source.getAddress() + '/api/users/', { headers });
  //       setUsers(userResponse.data);
        // const startDate = new Date();
        // const endDate = new Date();
        // startDate.setDate(startDate.getDate() - 30);
        // endDate.setDate(endDate.getDate() + 1);

  //       const reportParams = [
  //         { delete: 'True' },
  //         { type: 'deposite', delete: 'False' },
  //         { type: 'withdraw', delete: 'False' },
  //         {delete:'False'},
  //       ];

        // const reportRequests = reportParams.map((params) =>
        //   axios.get(Source.getAddress() + '/api/report/', {
        //     headers,
        //     params: {
              // startDate: startDate.toISOString(),
              // endDate: endDate.toISOString(),
        //       ...params,
        //     },
        //   })
        // );

  //       const [draftResponse, depositeResponse, withdrawResponse,alltransactionsResponse] = await Promise.all(reportRequests);

  //       setdraft(draftResponse.data);
  //       setdeposite(depositeResponse.data);
  //       setwithdraw(withdrawResponse.data);
  //       setalltransactions(alltransactionsResponse.data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };

  //   const fetchSettings = async () => {
  //     try {
  //       const sett = await axios.get(Source.getAddress() + '/api/settings/', {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('access')}`,
  //         },
  //       });
  //       setsettings(sett.data);
  //     } catch (error) {
  //       console.error('Error fetching settings:', error);
  //     }
  //   };

  //   const fetchMoney = async () => {
  //     try {
  //       const token = localStorage.getItem('access');
  //       const res = await axios.get(Source.getAddress() + '/api/money/',
  //       {params: {
  //         delete: 'False',
  //       },
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         }
  //       });
  //       setMoney(res.data);
  //       setCash(res.data);
  //     } catch (err) {
  //       console.error('Error fetching money:', err);
  //     }
  //   };

  //   const fetchUsers = async () => {
  //     try {
  //       const res = await axios.get(Source.getAddress() + '/api/users/', {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('access')}`,
  //         },
  //       });
  //       setUsers(res.data);
  //     } catch (err) {
  //       console.error('Error fetching users:', err);
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };

  //   const handleResize = () => {
  //     setIsDesktop(window.innerWidth > 768);
  //   };

  //   if(window.pathname='')
  //   fetchAccounts();
  //   fetchBelance();
  //   fetchData();
  //   fetchSettings();
  //   fetchMoney();
  //   fetchUsers();

  //   // const savedState = localStorage.getItem('transactionCollapseState');
  //   // setIsTransactionCollapsed(savedState === 'true');
  //   // const savedStated = localStorage.getItem('transactionCollapseStated');
  //   // setIsTransactionCollapsedd(savedStated === 'true');
  //   window.addEventListener('resize', handleResize);
  //   return () => window.removeEventListener('resize', handleResize);
  // }, [money.length]); // Add all dependencies in the array
  // const { getCollapseProps, getToggleProps } = useCollapse({
  //   defaultExpanded: true, // Start with the section expanded or collapsed
  // });
  // const { getCollapseProps, getToggleProps } = useCollapse({
  //   defaultExpanded: true, // Start with the section expanded or collapsed
  // });
  // // Use the hook for All Draft section
  // const { getCollapseProps: getCollapsePropsDraft, getToggleProps: getTogglePropsDraft } = useCollapse({
  //   defaultExpanded: false, // Start with the section collapsed
  // });
  // const [isExpandedTransactions, isCollapsedTransactions] =useState(false);
  // const [isExpandedDraft, isCollapsedDraft] =useState(false);
  // const handlecollapse=()=>{
  //   isCollapsedTransactions(pre=>!pre);
  // }
  // const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({ defaultExpanded: false });
  // const { getCollapseProps: getCollapsePropsDraft, getToggleProps: getTogglePropsDraft, isExpanded: isExpandedDraft } = useCollapse({ defaultExpanded: false });


  // const {
  //   getCollapseProps,
  //   getToggleProps,
  //   isExpanded: isTransactionsExpanded
  // } = useCollapse({ defaultExpanded: false });

  // // Second collapsible section (default: closed)
  // const {
  //   getCollapseProps: getCollapsePropsDraft,
  //   getToggleProps: getTogglePropsDraft,
  //   isExpanded: isDraftExpanded
  // } = useCollapse({ defaultExpanded: false });
  return (
    <div className={`App ${settings[0].language === "Persian" ? "iransans" : "English"}`}>
     
      {/* {columns} */}
      <div
        className="wrapper fs-1"
        style={{ backgroundColor: "#ecf0f5", width: "100%" }}
      >
      <Sidebar isToggled={isToggled}/>
        <div
          className="main"
          onClick={(e) => {
            if (e.target.className === "main") {
              // console.log('hi calenders111');
            }
          }}
          style={{ height: "1000px!important", width: "1000px!important" }}
        >
          <nav
            className="navbar navbar-expand px-3 border-bottom "
            style={{
              backgroundColor: "#4a5cf2",
              boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              height: "3.4rem",
            }}
          >
            <button
              className="btn p-0 m-0 d-flex"
              onClick={()=>handleToggle()}
              id="sidebar-toggle"
              type="button"
            >
              {/* <span className="navbar-toggler-icon"></span> */}
              <img src={Menu} style={{ width: "30px" }} className="p-0 m-0" />
            </button>
            <div className="navbar-collapse navbar">
              <ul className="navbar-nav">
                <li className="nav-item dropdown">
                  <div className="dropdown-menu dropdown-menu-end">
                    <a href="/logout" className="bg-dark dropdown-item"></a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="btn-group dropstart">
              <button
                type="button"
                className="btn  dropdown-toggle table-hover text-white text-capitalize "
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ border: "none" }}
              >
                {localStorage.getItem("userToken")}
                <img
                  src={Accountp}
                  className="bg-white p-1 rounded-5 ms-2"
                  style={{ width: "2.5em" }}
                />
              </button>
              <ul className="dropdown-menu pe-5">
                {localStorage.getItem("userTokenname") === "admin" && (
                  <li>
                    <a
                      href="/manage"
                      className="dropdown-item fw-bold"
                      type="button"
                    >
                      <img className="w-25" src={Manager} alt="manager-icon" />
                      Manage Users
                    </a>
                  </li>
                )}
                <li>
                  <a
                    // href="/"
                    onClick={() =>{
                    handleLogout();
                    localStorage.setItem('access','');
                  }}
                    className="dropdown-item fw-bold"
                    type="button"
                  >
                    <img className="w-25" src={Logout} alt="logout-icon" />
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </nav>
              {(<Loading loading={loading}/>)}
          <MyProvider>
          {Component}
          </MyProvider>
        </div>
      </div>
    </div>
  );
}

export default App1;
