import axios from 'axios'
import { useState } from 'react';
import DataTable from 'react-data-table-component';
import React, { useEffect } from 'react'
import Source from '../../Source'
import AddSell from './forms/AddSell';
import Swal from 'sweetalert2';
import moment from "moment-jalaali";
import Add_customer from '../AddAccount';
import { useMyContext } from '../constext/UseContext';
import Add_belance from '../Add_belance';
export default function Sell() {
  // const [AddAccountModal,setAddAccountModal]=useState();
  const [belance,setbelance]=useState([]);
  const [options,setOptions]=useState([]);
  const [records, setRecords] = useState([]);
  const [addAccountModal,setAddAccountModal]=useState(false);
  const [AddSellModal,setAddSellModal] = useState(false);
  const [AddBalanceModal,setAddBalanceModal]=useState(false);
  
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/money', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      console.log(res);
      setmoney(res.data);
    }).catch((err)=>{
      console.log(err);
    });
   },[AddBalanceModal]);
  const date = (d) => {
    const date = new Date(d);
    let formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    let aa = ampm;
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    if (localStorage.getItem('date') === "Persian") {
      moment.locale('fa'); // تنظیم لوکال به فارسی
      aa = hours >= 12 ? "ب.ظ" : "ق.ظ";
      formattedDate = moment(d).format('jYYYY-jMM-jDD');
    } else {
      moment.locale('en'); // تنظیم لوکال به انگلیسی
    }
  
    return `${formattedDate} ${formattedHours}:${formattedMinutes} ${aa}`;
  };
  const handlePerRowsChange = async (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };
  // const [data, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0); // تعداد کل ردیف‌ها
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);  // صفحه فعلی
  const [perPage, setPerPage] = useState(10); // تعداد آیتم‌ها در هر صفحه

  // دریافت داده‌ها از سرور
  const fetchData = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await axios.get(Source.getAddress()+'/api/sell', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`,
        },
        params: {
          page: page,
          perPage: pageSize,
          delete:0,
        },
      });
      console.log(response);
      setRecords(response.data.data.data); // داده‌های صفحه جاری
      setTotalRows(response.data.data.total);
       // تعداد کل ردیف‌ها
      setLoading(false);
    } catch (error) {
      console.error('Error fetching data', error);
      setLoading(false);
    }
  };

  // فراخوانی داده‌ها هنگام بارگذاری صفحه یا تغییر صفحه
  useEffect(() => {
    fetchData(currentPage, perPage);
  }, [currentPage, perPage]);

  // مدیریت تغییر صفحه
  const handlePageChange = page => {
    setCurrentPage(page); // برو به صفحه جدید
  };

  const handleAdd=()=>{
    setAddAccountModal(true);  
    };
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.stock.type.picture}
          // alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Item Type",
      selector: (row) => row.stock.type.name,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Name",
      selector: (row) => row.stock.name,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: (
        <strong
          style={{ minWidth: "200px", maxWidth: "240px", width: "240px" }}
        >
          Date Created
        </strong>
      ),
      selector: (row) => date(row.dateInsert),
      style: {
        minWidth: "170px!important", // Adjust this value as needed
        maxWidth: "170px", // Adjust this value as needed
        margin:'0px'
        // Width: '600px',    // Set a specific width
      },
      // sortable: true,
    },
    { name: "Description", selector: (row) => row.description, sortable: true },
    { name: "Purchase Price", selector: (row) => row.purchase_price, sortable: true },
    { name: "Sell Price", selector: (row) => row.sell_price, sortable: true },
    { name: "Add By", selector: (row) => row.user.name, sortable: true },
    { name: "Qty", selector: (row) => row.qty, sortable: true },
    { name: "Rate", selector: (row) => row.rate, sortable: true },
    {
      name: <p className="mt-3">Serial Number</p>,
      selector: (row) => row.stock.serial_number,
      sortable: true,
      style: {
        width: "100px",
        minWidth: "100px",
      },
    },
    // { name: (<strong
    //   style={{
    //     textAlign: "center",
    //     backgroundColor: "tranceparent",
    //     width: "100%",
    //   }}
    // >
    //   Edit
    // </strong>),selector: (row) => (
    //   <button
    //     onClick={() => {
    //       // Edit_item(row);
    //       // setEdit(row);
    //       // setUpdate(true);
    //       // seTtitle('Edit Type Money');
    //     }}
    //     style={{
    //       border: "none",
    //       backgroundColor: "transparent",
    //       height: "100%",
    //     }}
    //   >
    //     <img
    //       height={"25%"}
    //       width={"25%"}
    //       src={pencil}
    //       style={{ backgroundColor: "tranceparent" }}
    //     />
    //   </button>
    // ) },
    // { name: (<strong
    //   style={{
    //     textAlign: "center",
    //     backgroundColor: "tranceparent",
    //     width: "100%",
    //   }}
    // >
    //   Delete
    // </strong>),   selector: (row) => (
    //   <button
    //     onClick={() => {
    //       // delete_item(row.id);
    //       // setEdit(row);
    //       // setUpdate(true);
    //       // seTtitle('Edit Type Money');
    //     }}
    //     style={{
    //       border: "none",
    //       backgroundColor: "transparent",
    //       height: "100%",
    //     }}
    //   >
    //     <img
    //       height={"20%"}
    //       width={"20%"}
    //       src={Trash}
    //       style={{ backgroundColor: "tranceparent" }}
    //     />
    //   </button>
    // ) },
  ];
  const [money,setmoney]=useState([]);
  const getcustomer=()=>{
      axios.get(Source.getAddress() + '/api/belance', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access')}`,
        },params: {
          delete: 'False',
        },
      }).then((res)=>{
        setbelance(res.data);
      }).catch((err)=>{
        Swal.fire({
          position: "top-end",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
          timer: 1000,
        });
      });
  }
  return (
    <div className={`w-100 ${"iransans"}`}>
      <div className="m-auto mt-5 m-5" style={{ height: "100%" ,width:"99%"}}>
        <button
          type="submit"
          className="btn btn-info mb-1 p-1"
          style={{ width: "100px" }}
          onClick={()=>{
            setAddSellModal(true);
            getcustomer();
          }}
        >
          Add
        </button>
        <div
          className="bg-light d-flex justify-content-lg-between"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <h2 className="m-2 fw-bold mb-4">Sell</h2>
            <select
              id=""
              name="gender"
              //   onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
              //   value={select_user}
            >
              <option value={"all users"} selected>
                All Users
              </option>
              {/* {user.map((item) =>(
              <option value={item.name} selected>{item.name}</option>
              ))} */}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            // onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
      columns={columns}
      data={records}
      progressPending={loading}
      striped
        responsive
        highlightOnHover
      pagination
      paginationServer // فعال‌سازی صفحه‌بندی سرور ساید
      paginationTotalRows={totalRows} // تعداد کل ردیف‌ها
      onChangePage={handlePageChange} // تغییر صفحه
      onChangeRowsPerPage={handlePerRowsChange} // تغییر تعداد ردیف‌ها در صفحه
      customStyles={{
        headCells: {
          style: {
            display: 'flex',
            minWidth: '100px', // تنظیم اندازه عنوان‌ها
            maxWidth: '100px',
            width: '10px',
            margin: '0px',
            fontWeight:'bold',
          },
        },
        cells: {
          style: {
            minWidth: '100px', // تنظیم اندازه داده‌ها
            maxWidth: '150px',
            width: '150px',
          },
        },
      }}
    />
        {/* {
          <AccountEdit
          loading={loading}
          setLoading={setLoading}
          inputRef={inputRef1}
            close={() => setEdit(false)}
            account={editAccount1}
            setAccount={setEditAccount1}
            // records={records}
            // setRecords={setRecords}
            edit={edit}
            records={records}
            setRecords={setRecords}
          />
        }
        <Belance
        setMoney={setMoney}
        inputRef={inputRefbelance}
        belancem={belance}
         setbelance={setbelance}
        prof={prof}
        idprof={idprof}
        customer={customer}
        setcustomer={setcustomer}
        moneyp={money}
        loading={loading}
        setLoading={setLoading}
          close={() => setOpenBelance(false)}
          id={id}
          accountbelance={editAccount}
          setEditAccount={setEditAccount}
          openBelance={openBelance}
          setOpenBelance={setOpenBelance}
        />
        {
          <AddAccount
          loading={loading}
          setLoading={setLoading}
            inputRef={inputRef}
            close={() => setAddAccountModal(false)}
            addAccountModal={addAccountModal}
            records={records}
            setRecords={setRecords}
          />
        } */}
              <AddSell
              records={records}
              setRecords={setRecords}
              addAccountModal={addAccountModal}
              setAddAccountModal={setAddAccountModal}
              // stock={stock}s
              moneys={money}
              AddSellModal={AddSellModal}
              close={()=>setAddSellModal(false)}    
              belance={belance}
              setbelance={setbelance}
              setAddBalanceModal={setAddBalanceModal}
              />
          <Add_belance
          // customers={customers}
          accounts={belance}
          setAccounts={setbelance}
          close={() => setAddBalanceModal(false)}
          addAccountModal={AddBalanceModal}
          records={belance}
          setRecords={setbelance}
          money={money}
          // settings={settings}
          setAddcustomerModal={setAddAccountModal}
        />
        {<Add_customer
              // loading={loading}
              // setLoading={setLoading}
                // inputRef={inputRef}
                close={() => setAddAccountModal(false)}
                addAccountModal={addAccountModal}
                records={options}
                setRecords={setOptions}            
            />}
      </div>
    </div>
  );
}
