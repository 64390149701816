import React, { useEffect, useState,useCallback } from 'react';
import Select ,{components} from 'react-select';

const Combo_Customer = ({isEditable=false,name ,type,options,Onsearch,selectedOption,setAddAccountModal,setSelectedOption,searchQuery, setSearchQuery,handleInputChange,handleSearch,handleAdd}) => {
  const handleChange = (sselectedOption) => {
    // if (selectedOption) {
    setSelectedOption(sselectedOption);
    Onsearch(sselectedOption);
    // } else {
    //  setSelectedOption([]);
    //  setSelectedOption({name:''})
    // }
};
const CustomMenuList = (props) => {
  return (
    <components.MenuList {...props}>
      {props.children}
     {type &&<div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "10px",
          borderTop: "1px solid #eaeaea",
          cursor: "pointer",
          backgroundColor: "#f9f9f9",
        }}
        onClick={()=>setAddAccountModal(true)}
      >
        {/* <strong>Add "{searchQuery}"</strong> */}
        <strong>{name}</strong>
      </div>}
    </components.MenuList>
  );
};
  return (
    <div className='w-40'>
      <Select
      inputValue={searchQuery}
        value={selectedOption}
        onChange={handleChange}
        options={options}
        onInputChange={handleInputChange} 
        getOptionLabel={(option) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span>{`${option.name} `}</span>
          </div>)}
        getOptionValue={(option) => `${option.name}`}
        placeholder="Search..."
        isClearable
        components={{ MenuList: CustomMenuList }}
        isSearchable
        isDisabled={isEditable}
      />

    </div>
  );
};

export default Combo_Customer;