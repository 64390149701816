import React, { createContext, useState, useContext } from 'react';

// ایجاد Context
const MyContext = createContext();

// ایجاد Provider
export const MyProvider = ({ children }) => {
  const [state, setState] = useState();  // مقدار اولیه

  return (
    <MyContext.Provider value={{ state, setState }}>
      {children}
    </MyContext.Provider>
  );
};

// استفاده از Context
export const useMyContext = () => {
  return useContext(MyContext);
};
