import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Source from "../../Source";

export default function Reset({ addAccountModal,close }) {
  const [selectedTables, setSelectedTables] = useState({
    accounts: false,
    balance: false,
    report: false,
    money: false,
  });

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setSelectedTables((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleSubmit = async () => {
    const tablesToReset = Object.keys(selectedTables).filter(
      (table) => selectedTables[table]
    );
    console.log(tablesToReset);

    if (tablesToReset.length === 0) {
      Swal.fire("Error", "Please select at least one table to reset.", "error");
      return;
    }

    try {
      const response = await axios.post(Source.getAddress()+"/api/empty-selected-tables", {
        tables: tablesToReset,
      },{headers: {
        Authorization: `Bearer ${localStorage.getItem("access")}`,
      },});
      Swal.fire("Success", response.data.message, "success");
    } catch (error) {
      Swal.fire("Error", "Failed to reset tables.", "error");
      console.error(error);
    }
    close()
  };

  return (
    <div
      className={`rounded-4 row g-2 popup m-4 mb-0 ${addAccountModal && "show"}`}
      style={{ width: "26rem" }}
    >
      <div className="d-flex justify-content-end m-0 p-0">
        <table className="table table-light table-striped">
          <thead>
            <tr>
              <h2 className="m-2 fw-bold mb-4 w-100">Reset Options</h2>
            </tr>
          </thead>
          <tbody className="table-group-divider">
            <tr>
              <td className="d-flex justify-content-between">
                <strong className="fs-5 p-1 me-5">Customers</strong>
                <div className="form-check form-switch pt-2 w-25">
                  <input
                    className="form-check-input align-bottom m-0 w-50 h-75 ms-4"
                    type="checkbox"
                    id="accounts"
                    checked={selectedTables.accounts}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="d-flex justify-content-between">
                <strong className="fs-5 p-1 me-5">Accounts</strong>
                <div className="form-check form-switch pt-2 w-25">
                  <input
                    className="form-check-input align-bottom m-0 w-50 h-75 ms-4"
                    type="checkbox"
                    id="balance"
                    checked={selectedTables.balance}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="d-flex justify-content-between">
                <strong className="fs-5 p-1 me-5">Deposite & Withdraw</strong>
                <div className="form-check form-switch pt-2 w-25">
                  <input
                    className="form-check-input align-bottom m-0 w-50 h-75 ms-4"
                    type="checkbox"
                    id="report"
                    checked={selectedTables.report}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <td className="d-flex justify-content-between">
                <strong className="fs-5 p-1 me-5">Cash</strong>
                <div className="form-check form-switch pt-2 w-25">
                  <input
                    className="form-check-input align-bottom m-0 w-50 h-75 ms-4"
                    type="checkbox"
                    id="money"
                    checked={selectedTables.money}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-10 ms-4 m-10 mt-5 ps-3 d-flex">
        <button
          className="text-center form-control btn btn-danger text-light me-1"
          style={{ margin: "auto", width: "150px" }}
          onClick={()=>close()}
        >
          Cancel
        </button>
        <button
          className="text-center btn form-control btn-success text-light ms-1"
          style={{ margin: "auto", width: "150px" }}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
