import React, { useEffect, useRef, useState } from "react";
import Source from "../../../Source";
import axios from "axios";
import Swal from "sweetalert2";
import Profile from "../../../assets/icon/profile.png";
import IdNational from "../../../assets/icon/national_id.png";
import Datepicker_Customer from "../../forms/Datepicker_customer";
import moment from "moment-jalaali";
import jalaali from "jalaali-js";
import Combo_Customer from "../../forms/Combo_Customer";
import Additem from "./AddItem";

export default function Edititem({
  AddItemModal,
  close,
  Itemtype,
  records,
  setRecords,
  AddItem,
  setAddItem,
  selectedOption, setSelectedOption
}) {
  console.log(AddItem.type);
  const [selectedDay, setSelectedDay] = useState(moment()); 
  const [EndDate, setEndDate] = useState(moment());

  const algorithm=(e)=>{
    setAddItem({
      ...AddItem,
      type_id: e.id,
    });
  };
  const handle_date = (jalaliDate) => {
    if (jalaliDate) {
      const { year, month, day } = jalaliDate;
      const gregorianDate = jalaali.toGregorian(year, month, day);
      // Get current time
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      // Create the ISO date string
      let isoDateString;
      const t = new Date();
      const date = new Date(
        jalaliDate.year,
        jalaliDate.month - 1,
        jalaliDate.day,
        t.getHours(),
        t.getMinutes()
      );

      // const date = new Date(jalaliDate.year, jalaliDate.month - 1, jalaliDate.day);
      const isoString = date.toISOString(); // This gives you the ISO string in UTC
      // setIsoDate(isoString);
      setAddItem({
        ...AddItem,
        date_creation: isoString,
      });
      // const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  };
  const sumbit=()=>{
    const uploadData ={
      name:AddItem.name,
      type_id:AddItem.type_id,
      serial_number:AddItem.serial_number,
      date_creation:AddItem.date_creation,
      description:AddItem.description  
    };
    // console.log(uploadData);
    axios.put(Source.getAddress()+'/api/item/'+AddItem.id,uploadData, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem("access")}`,
      },
    }).then((res)=>{
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Item has been Updated!",
        showConfirmButton: false,
        timer: 1000,
      });
      let item={
        id:res.data.id,
        name:AddItem.name,
    type:selectedOption,
    isdelete:0,
    description:AddItem.description,
    date_creation:AddItem.date_creation,
    serial_number:AddItem.serial_number,
      }
      // setRecords([item, ...records]);
      setRecords(prevRecords =>
        prevRecords.map(record =>
          record.id === AddItem.id ? {...record,...item} : record
        )
      );
      close();
    }).catch((error)=>{
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 1000,
      });
    })
  }
  const handleItem = (e) => {
    setAddItem({
      ...AddItem,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div
      className={`container rounded-5 popup item ${
        AddItemModal ? "show" : ""
      }`}
      style={{
        width: "100%",
        maxWidth: "40% !important",
        overflowX: "auto",
        overflowY: "auto",
        height: "80vh",
        backgroundColor: "#f8f9fa",
        padding: "20px",
      }}
    >
      <div className="d-flex justify-content-end">
        <button
          type="button"
          className="btn-close p-3 m-1 mt-0 hover_btn"
          onClick={close}
          aria-label="Close"
        ></button>
      </div>
      <div className="p-1 rounded-5">
        <div className="row">
          <h1
            className="text-center rounded p-4 text-light"
            style={{ backgroundColor: "var(--bs-info)", width: "100%" }}
          >
            Update Item
          </h1>
          <div className="col-12 mt-5">
            <label htmlFor="name" style={{ fontWeight: "bold" }}>
              Name
            </label>
            <input
              // ref={inputRef}
              // onKeyDown={handleKeyDown}
              onChange={handleItem}
              value={AddItem.name}
              type="text"
              name="name"
              placeholder="Name"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            {/* <label htmlFor="father_name" style={{ fontWeight: "bold" }}>
              Item Type
            </label>
            <input
              // onKeyDown={handleKeyDown}
              onChange={handleItem}
              // value={father_name}
              type="text"
              name="type_id"
              placeholder="Father Name"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            /> */}
            <label for="validationServer01" className="fw-bold">
          Item Type
          </label>
        < Combo_Customer
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
          options={Itemtype}
          Onsearch={algorithm}
        />
            <label htmlFor="phone_number" style={{ fontWeight: "bold",marginTop:"1rem" }}>
              Serial Number
            </label>
            <input
              // onKeyDown={handleKeyDown}
              onChange={handleItem}
              value={AddItem.serial_number}
              type="text"
              name="serial_number"
              maxLength={10}
              placeholder="Phone Number"
              className="form-control fs-6 mb-3"
              style={{ width: "100%" }}
            />
            <Datepicker_Customer
            // onKeyDown={handleKeyDown}
            default_value={EndDate}
            // settings={settings}
            handle_date={handle_date}
            lebal={"Date"}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
          ></Datepicker_Customer>
             <div class="form-floating">
            <textarea
              // onKeyDown={handleKeyDown}
              name="description"
              class="form-control h-50"
              placeholder="Leave a comment here"
              id="floatingTextarea2"
              value={AddItem.description}
              
              onChange={handleItem}
            ></textarea>
            {/* <label for="floatingTextarea2" className="index-2">Discription</label> */}
          </div>
          </div>
          
          <div className="col-12 d-flex justify-content-center mt-2">
            <button
              type="button"
              className="text-center btn btn-danger text-light me-2"
              style={{ width: "150px" }}
              onClick={close}
            >
              Cancel
            </button>
            <button
              // ref={buttonRef}
              type="button"
              className="text-center btn btn-success text-light ms-2"
              style={{ width: "150px" }}
              onClick={sumbit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
