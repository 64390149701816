import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import Source from "../Source";
import Trash from "../assets/icon/trash.png";
import pencil from "../assets/icon/pencil.png";
import AddMoney from "./forms/AddMoney";
import Swal from "sweetalert2";
import UpdateMoney from "./forms/Updatemoney";
import "./forms/money.css";
export default function Money({user, settings }) {
  const [money, setMoney]=useState([]);
  const [title, seTtitle] = useState("");
  const [add, setAdd] = useState(false);
  const [update, setUpdate] = useState(false);
  const [error, setError] = useState(false);
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/money', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 0,
      },
    }).then((res)=>{
      console.log(res);
      setMoney(res.data);
      setRecords(res.data);
    }).catch((err)=>{
      console.log(err);
    });
  },[]);
  const [record, setRecords] = useState(); 
  const Delete = async (row) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    const result = await swalWithBootstrapButtons.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    });
    if (result.isConfirmed) {
      let moneyUpdate = {
        cach: "",
        existense: "True",
        name: "",
        ontransaction: "",
        user: "",
      };
      moneyUpdate.cach = row.cach;
      moneyUpdate.name = row.name;
      moneyUpdate.ontransaction = "False";
      moneyUpdate.user = row.user;
      const uploadData = new FormData();
      for (let key in row) {
        if (row.hasOwnProperty(key)) {
          if (
            String(key) !== "id" &&
            String(key) !== "user_name" &&
            String(key) !== "existense"
          ) {
            uploadData.append(String(key), row[key]);
          }
        }
      }
      uploadData.append("existense", "True");

      fetch(Source.getAddress() + "/api/money/" + `${row.id}/`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`, // Add the access token here
          // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
        },
        body: uploadData,
      })
        .then((res) => {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Money deleted successfully !",
            showConfirmButton: false,
            timer: 600,
          });
          setRecords(record.filter((a) => a.id !== row.id));
        })
        .catch((err) => {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Something went wrong !",
            showConfirmButton: false,
            timer: 600,
          });
        });
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      swalWithBootstrapButtons.fire({
        title: "Cancelled",
        text: "Your imaginary file is safe :)",
        icon: "error",
      });
    }
  };
  const [money1, setmoney] = useState({
    name: "",
    existense: 0,
    user_id: localStorage.getItem("userTokenid"),
    cach:0
  });
  // useEffect(() => {
  //   setRecords(money);
  // }, [money]);
  const [edit, setEdit] = useState({
    id: "",
    name: "",
  });
  const Addmoney = (Money) => {
    if (Money.name !== "") {
      console.log(Money);
      axios
        .post(`${Source.getAddress()}/api/money`, Money, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
        })
        .then((response) => {
          // پردازش پاسخ
          const newId = response.data.id;
          let add = {
            id: newId,
            name: Money.name,
            existense: (Money.existense===1) ? 1 : 0, // استفاده از مقدار پیش‌فرض
            user_name: localStorage.getItem("userToken"),
            user: Money.user,
          };
          setRecords([add, ...record]);
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "Money added successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
          setmoney({
            name: "",
            existense: 0,
            user_id: localStorage.getItem("userTokenid"),
            cach:0
          });
          setAdd(false);
        })
        .catch((error) => {
          console.error("Error details:", error); // چاپ جزئیات خطا
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: error.response.data.name,
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } else {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "You must fill name!",
        showConfirmButton: false,
        timer: 1500,
      });
      setError(true);
    }
  };

  const [select_user, setselect_user] = useState("");

  const ChangeUser = (e) => {
    if (e.target.value !== "all users") {
      const newData = money.filter((row) => {
        if (typeof row.user_name === "string") {
          return row.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      });
      setRecords(newData);
      // console.log(e.target.value);
    } else {
      setRecords(money);
    }
    // else{setMoney(accounts)}
    setselect_user(e.target.value);
  };

  const inputRef = useRef(null);
  const updateRef = useRef(null);

  return (
    <div
      className={`continer w-100 h-100 English ${
        settings[0].language === "Persian" && "iransans"
      }`}
      onClick={(e) => {
        if (
          e.target.className == "continer w-100 h-100" ||
          e.target.className == "me-auto ms-auto pt-4 pb-4 h-100 w-50"
        ) {
          setAdd(false);
          setUpdate(false);
          setError(false);
        }
      }}
    >
      <div className="me-auto ms-auto pt-4 pb-4 h-100 resm">
        <button
          onClick={() => {
            setAdd(true);
            setUpdate(false);
            inputRef.current.select();

            seTtitle("Add Money Type");
          }}
          type="button"
          class="btn btn-info text-white col m-1 mt-1 ps-2 pe-2 fs-6"
        >
          Add Money Type
        </button>
        <div
          class="card card-body m-auto"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <h1 class="text-center rounded m-0 mb-2 p-4 text-light fw-bold bg-info">
            Table Of Money
          </h1>
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <select
              id=""
              name="gender"
              onChange={ChangeUser}
              class="form-select h-50 m-auto mb-2"
              style={{ width: "20%" }}
              value={select_user}
            >
              <option value={"all users"} selected>
                All Users
              </option>
              {user.map((item) => (
                <option value={item.name} selected>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <table class="table table-light table-striped">
            <thead>
              <tr>
                <th scope="col">No</th>
                <th scope="col">Name</th>
                <th scope="col">Added by</th>
                <th scope="col">Delete</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody class="table-group-divider">
              {record &&
                record.map((row, idx) => {
                  return (
                    <tr key={idx}>
                      <th scope="row">{row.id}</th>
                      <td>{row.name}</td>
                      <td>{row.user_name}</td>
                      <td style={{ width: "5%" }}>
                        {!row.ontransaction && (
                          <button
                            style={{
                              border: "none",
                              background: "tranceparent",
                            }}
                            onClick={() => Delete(row)}
                          >
                            <img
                              height={"50%"}
                              width={"50%"}
                              src={Trash}
                              style={{
                                background: "transparent",
                              }}
                            />
                          </button>
                        )}
                      </td>
                      <td style={{ width: "5%" }}>
                        {!row.ontransaction && (
                          <button
                            onClick={() => {
                              setAdd(false);
                              setEdit(row);
                              setUpdate(true);
                              seTtitle("Edit Type Money");
                              updateRef.current.select();
                            }}
                            style={{
                              border: "none",
                              backgroundColor: "tranceparent!important",
                            }}
                          >
                            <img height={"90%"} width={"90%"} src={pencil} />
                          </button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {/* {source.length>=6 && <a o className="text-primary underlined text-center">{text}</a>} */}
            </tbody>
          </table>
          {
            <UpdateMoney
              setRecords={setRecords}
              record={record}
              updateRef={updateRef}
              update={update}
              setAdd={setUpdate}
              money={edit}
              setmoney={setEdit}
            />
          }
          {
            <AddMoney
              inputRef={inputRef}
              add={add}
              setError={setError}
              title={title}
              setAdd={setAdd}
              error={error}
              Addmoney={Addmoney}
              setmoney={setmoney}
              money={money1}
            />
          }
        </div>
      </div>
    </div>
  );
}
