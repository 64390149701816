import React, { useEffect, useState } from "react";
import Select from "react-select";
import axios from "axios";
import Source from "../../Source";
import jalaali from "jalaali-js";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Multiselect from 'multiselect-react-dropdown';
import { stringify } from 'qs';
import moment from "moment-jalaali";
import '../Report/report.css';
// import Datepicker_customer from "../forms/Datepicker_customer";
import Datepicker_Customer from ".././forms/Datepicker_customer";
import { InputDatePicker } from "jalaali-react-date-picker";

export default function ReportCustomers() {
  const [customers, setcustomers] = useState();
  const [accounts, setaccounts] = useState([]);
  const [selectedDayStart, setselectedDayStart] = useState();
  const [selectedDayEnd, setselectedDayEnd] = useState();
  const [Date_Start, setDate_Start] = useState();
  const [Date_End, setDate_End] = useState();
  const [Records, setRecords] = useState([]);
  const [settings, setsettings] = useState([{
    name: '',
    email: '',
    phone: '',
    address: '',
    description: '',
  }]);
  const handleDateChangeStart = (date) => {
    setselectedDayStart(date);
    if (date) {
      const handl = { year: date._a[0], month: date._a[1] + 1, day: date._a[2] }
      handle_date(handl);
    }
  };
  const handleDateChangeEnd = (date) => {
    setselectedDayEnd(date);
    if (date) {
      const handl = { year: date._a[0], month: date._a[1] + 1, day: date._a[2] };
      handle_dateEnd(handl);
    }
  };
  const handle_date = (jalaliDate) => {
    if (jalaliDate) {
      const { year, month, day } = jalaliDate;
      const gregorianDate = jalaali.toGregorian(year, month, day);
      // Get current time
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      // Create the ISO date string
      let isoDateString;
      const t = new Date();
      const date = new Date(
        jalaliDate.year,
        jalaliDate.month - 1,
        jalaliDate.day,
        t.getHours(),
        t.getMinutes()
      );

      // const date = new Date(jalaliDate.year, jalaliDate.month - 1, jalaliDate.day);
      const isoString = date.toISOString()   // This gives you the ISO string in UTC
      // setIsoDate(isoString);
      // setAddDeposite({
      //   ...AddDeposite,
      //   date: isoString,
      // });
      setDate_Start(isoString);
      // const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  };
  const handle_dateEnd = (jalaliDate) => {
    if (jalaliDate) {
      const { year, month, day } = jalaliDate;
      const gregorianDate = jalaali.toGregorian(year, month, day);
      // Get current time
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      // Create the ISO date string
      let isoDateString;
      const t = new Date();
      const date = new Date(
        jalaliDate.year,
        jalaliDate.month - 1,
        jalaliDate.day,
        t.getHours(),
        t.getMinutes()
      );

      // const date = new Date(jalaliDate.year, jalaliDate.month - 1, jalaliDate.day);
      const isoString = date.toISOString()   // This gives you the ISO string in UTC
      // setIsoDate(isoString);
      // setAddDeposite({
      //   ...AddDeposite,
      //   date: isoString,
      // });
      setDate_End(isoString);
      // const isoDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValues(selectedList);
  };

  const [selectedValues, setSelectedValues] = useState([]);
  const [Belance, setBelance] = useState([]);
  
  useEffect(() => {
    const fetchCustomers = async () => {
      const token = localStorage.getItem("access");
      if (!token) return;
      const fetchWithToken = async (token) => {
        try {
          const response = await axios.get(
            Source.getAddress() + "/api/customers",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
              params: {
                delete: "False",
              },
            }
          );
          setcustomers(response.data);
        } catch (error) {
          if (error.response && error.response.status === 401) {
            const refreshToken = localStorage.getItem("refresh");
            if (!refreshToken) return;

            try {
              const refreshResponse = await axios.post(
                Source.getAddress() + "/api/token/refresh",
                {
                  refresh: refreshToken,
                }
              );
              const newAccessToken = refreshResponse.data.access;
              localStorage.setItem("access", newAccessToken);
              await fetchWithToken(newAccessToken);
            } catch (refreshError) {
              console.error("Error refreshing access token:", refreshError);
            }
          } else {
            console.error("Error fetching accounts:", error);
          }
        }
      };
      await fetchWithToken(token);
    };
    const fetchSettings = async () => {
      try {
        const sett = await axios.get(Source.getAddress() + '/api/settings', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access')}`,
          },
        });
        setsettings(sett.data);
      } catch (error) {
        console.error('Error fetching settings:', error);
      }
    };
    fetchSettings();
    fetchCustomers();
  }, []);

  const fetchAccounts = async (customerid) => {
    const token = localStorage.getItem("access");
    if (!token) return;
    const fetchWithToken = async (token) => {
      try {
        const res = await axios.get(Source.getAddress() + '/api/belance', {
          params: {
            delete: 'False',
            CustomerId: customerid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setaccounts(res.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          const refreshToken = localStorage.getItem("refresh");
          if (!refreshToken) return;

          try {
            const refreshResponse = await axios.post(
              Source.getAddress() + "/api/token/refresh",
              {
                refresh: refreshToken,
              }
            );
            const newAccessToken = refreshResponse.data.access;
            localStorage.setItem("access", newAccessToken);
            await fetchWithToken(newAccessToken);
          } catch (refreshError) {
            console.error("Error refreshing access token:", refreshError);
          }
        } else {
          console.error("Error fetching accounts:", error);
        }
      }
    };

    await fetchWithToken(token);
  };
  const [Customer, setCustomer] = useState();
  const [Report, SetReport] = useState([]);
  const handleCustomer = (data) => {
    setCustomer(data);
    if (data) {
      fetchAccounts(data.id);
    }
  };
  const date = (d) => {

    const date = new Date(d);

    let formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    let aa = ampm;
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
    if (localStorage.getItem('date') === "Persian") {
      moment.locale('fa'); // تنظیم لوکال به فارسی
      aa = hours >= 12 ? "ب.ظ" : "ق.ظ";
      formattedDate = moment(d).format('jYYYY-jMM-jDD');
    } else {
      moment.locale('en'); // تنظیم لوکال به انگلیسی
    }

    return `${formattedDate} ${formattedHours}:${formattedMinutes} ${aa}`;
  };
  // import jsPDF from 'jspdf';
  // import 'jspdf-autotable';
  
let img=null;
function fetchImageAndConvertToBase64(imageUrl) {
  fetch(Source.getAddress()+'/api/getImageBase64/'+imageUrl)
       .then(response => response.json())  // واکشی داده‌ها از API
       .then(data => {
           img = data.image;
          console.log(data.image);
          })
       .catch(error => console.error('Error fetching image:', error))
}
// فراخوانی تابع با آدرس تصویر

  const generatePDF = () => {
    if(!Customer){
      alert('لطفا یک مشتری را انتخاب کنید');
      return;
    }
    const doc = new jsPDF();
  
    // تنظیم رنگ پس‌زمینه برای عنوان
    doc.setFillColor(70, 130, 180); // رنگ آبی روشن
    doc.rect(10, 10, 190, 20, 'F'); // ایجاد یک مستطیل پر شده برای پس‌زمینه
  
    // افزودن عنوان با رنگ متن سفید
    doc.setFontSize(18);
    doc.setTextColor(255, 255, 255); // رنگ سفید برای متن
    doc.text('Report Cache Customer', 105, 25, null, null, 'center');
  
    // ریست کردن رنگ متن برای ادامه محتوا
    doc.setTextColor(0, 0, 0); // تنظیم رنگ مشکی برای ادامه محتوا
  
    // اطلاعات شرکت
    doc.setFontSize(12);
    doc.text(`Company Name: ${settings[0].company_name}`, 10, 40);
    doc.text(`Address: ${settings[0].address}`, 10, 50);
    doc.text(`Phone: ${settings[0].phone}`, 10, 60);
    doc.text(`Email: ${settings[0].email}`, 10, 70);  
    doc.text(`Customer: ${Customer.name}`, 10, 80);
    // بررسی وجود تصویر شرکت
    if (settings[0].company_pic) {
      // اگر تصویر موجود بود، آن را اضافه کنید
      // fetchImageAndConvertToBase64(settings[0].company_pic);
      // console.log(settings[0].company_pic);
  //     let imgage2;
  //     console.log('hi');
      const url = settings[0].company_pic;
      const fileName = url.split('/').pop();  // تقسیم رشته و گرفتن آخرین بخش
      // console.log(fileName);  // نتیجه: 1733331827.jpg
  //     console.log(Source.getAddress());
  //     // fetchImageAndConvertToBase64(fileName);
  //    // بررسی و دریافت تصویر Base64 از سرور
  //    fetch(Source.getAddress() + '/api/getImage/' +fileName)
  // .then(response => {
  //   if (!response.ok) {
  //     throw new Error("تصویر یافت نشد");
  //   }
  //   // دریافت URL تصویر
  //   const imageUrl = response.url;

    // اضافه کردن تصویر به PDF
    doc.addImage(Source.getAddress()+"/api/getImage/"+fileName, 'JPEG', 150, 34, 50, 50);
  // })
  // .catch(error => console.error('خطا در دریافت تصویر:', error));

    //  .then(response => {
    //    if (!response.ok) {
    //      throw new Error("تصویر یافت نشد");
    //    }
    //    console.log(response.blob);
    //    return response.blob(); // دریافت داده‌ها به صورت Blob
    //  })
    //  .then(blob => {
    //    // تبدیل Blob به URL موقت
    //    const imageUrl = URL.createObjectURL(blob);
    //    console.log(imageUrl);
    //    // اضافه کردن تصویر به PDF
    
    //    // آزاد کردن URL موقت پس از استفاده
    //   //  URL.revokeObjectURL(imageUrl);
    //  })
    //  .catch(error => console.error('خطا در دریافت تصویر:', error));
    
      //  doc.addImage(settings[0].company_pic, 'JPEG', 150, 34, 50, 50);
    
    } else {
      // اگر تصویر موجود نبود، یک متن جایگزین درج کنید
      doc.text('No Company Image Available', 150, 34);
    }
    
    // اطلاعات مشتری
    // داده‌های جدول
    const tableColumn = ['No', 'Description', 'Date', 'Currency', 'Deposit', 'Withdraw', 'Balance'];
    const tableRows = [];
  
    let currentBalance = 0;
    let No = 0;
    let previousAccountId = null;
    let accountTotalDeposit = 0;
    let accountTotalWithdraw = 0;
  
    Records.forEach((row, index) => {
      if (row.account.id !== previousAccountId) {
        currentBalance = Belance.find((account) => account.id === row.account_id).balance || 0;
        previousAccountId = row.account.id;
        No = 1;
        accountTotalDeposit = 0;
        accountTotalWithdraw = 0;
      } else {
        No += 1;
      }
      if (row.type === 'deposite') {
        currentBalance += parseInt(row.amount);
        accountTotalDeposit += parseInt(row.amount);
      } else if (row.type === 'withdraw') {
        currentBalance -= parseInt(row.amount);
        accountTotalWithdraw += parseInt(row.amount);
      }
  
      tableRows.push([
        No,
        row.description,
        date(row.date_created),
        row.account.type.name,
        { text: row.type === 'deposite' ? row.amount : '0', styles: { textColor: [6, 99, 56] } }.text, // رنگ سبز برای Deposit
        { text: row.type === 'withdraw' ? row.amount : '0', styles: { textColor: [255, 0, 0] } }.text, // رنگ قرمز برای Withdraw
        currentBalance
      ]);
  
      // اگر این آخرین تراکنش برای حساب است، ردیف خلاصه را اضافه کنید
      if (index === Records.length - 1 || Records[index + 1].account_id !== row.account_id) {
        tableRows.push([
          '',
          `Total for Account ${row.account.type.name}`,
          '',
          '',
          { text: accountTotalDeposit, styles: { textColor: [6, 99, 56]} }.text, // رنگ سبز برای Total Deposit
          { text: accountTotalWithdraw, styles: { textColor: [255, 0, 0] } }.text, // رنگ قرمز برای Total Withdraw
          currentBalance
        ]);
      }
    });
  
    // اضافه کردن جدول به PDF
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 90, // شروع جدول از این موقعیت عمودی
      styles: {
        cellPadding: 2,
        fontSize: 10,
        valign: 'middle',
      },
      headStyles: {
        fillColor: [0, 123, 255], // رنگ پس‌زمینه سرستون‌ها
        textColor: [255, 255, 255] // رنگ متن سرستون‌ها
      },
      alternateRowStyles: {
        fillColor: [240, 248, 255] // رنگ ردیف‌های alternate (آبی کم‌رنگ)
      },
      columnStyles: {
        4: { textColor: [0, 255, 0] }, // رنگ سبز برای ستون Deposit
        5: { textColor: [255, 0, 0] }  // رنگ قرمز برای ستون Withdraw
      }
    });
  
    // ذخیره PDF
    doc.save('report.pdf');
  };
  
  
  
  const Submit = () => {
    if (Date_Start && Date_End) {
      let searchaccount=[];
      let values=selectedValues;
      values.forEach(element => {
      let add={id:element.id};
      searchaccount.push(add);
  })
      axios.get(Source.getAddress() + "/api/report",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access")}`,
          },
          params: {
            AccountId: JSON.stringify(searchaccount),
            startDate: Date_Start,
            endDate: Date_End,
            report:'true'
          },
          paramsSerializer: params => stringify(params, { arrayFormat: 'brackets' })
        }
      ).then((res) => {
        setBelance(values);
        let belance = [];
        selectedValues.forEach(element => {
          let add = {
            id: element.id,
            belance: parseInt(element.belance)  
          }
          belance.push(add);
        });
        let prevAccount = 0;
        res.data.forEach(element => {
          if (element.account !== prevAccount) {
            if (element.type === 'deposite') {
              const updatedData = belance.map(item => {
                if (item.id === element.account) {
                  let total = item.belance - parseInt(element.amount)
                  return { ...item, belance: total };  // Replacing the name for id 2
                }
                return item;  // Return the original item if no match
              });
              belance = updatedData;
            } else {
              const updatedData = belance.map(item => {
                if (item.id === element.account) {
                  let total = item.belance + parseInt(element.amount)
                  return { ...item, belance: total };  // Replacing the name for id 2
                }
                return item;  // Return the original item if no match
              });
              belance = updatedData;
            }
          }
        });
        console.log(belance);
        setBelance(belance);
        setRecords(res.data);
      }).catch((err) => {
        console.log(err);
      })
    } else if (Date_Start) {

    }
  };
  let previousAccountId = null;
  let No = 0;

  let currentBalance = 0;
  let accountTotalDeposit = 0;
  let accountTotalWithdraw = 0;
  
  return (
<div className="w-100 h-100
  responsive-container">
    {/* English iransans */}
  <div className="pt-4 pb-4 h-100 ">
    <button
      onClick={() => generatePDF()}
      type="button"
      className="btn btn-outline-success fw-bold mb-2"
    >
      Print Pdf
    </button>
    <div className="rounded-3 px-4 p-1 mb-2" style={{ boxShadow: '2px 2px 2px 0 rgba(0, 0, 0, 0.2), 0 0px 2px 2px rgba(0, 0, 0, 0.1)' }}>
      <h2 className="mt-3">Create Report From a Customer</h2>
      <div className="d-flex flex-column flex-sm-row mb-3 mt-1">
        <div className="form-item me-3">
          <label>Customer Name</label>
          <Select
            value={Customer}
            onChange={handleCustomer}
            options={customers}
            getOptionLabel={(option) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>{`${option.name}`}</span>
              </div>
            )}
            getOptionValue={(option) => `${option.name}`}
            placeholder="Search..."
            isClearable
            isSearchable
          />
        </div>
        <div className="form-item me-3">
          <label>Account Type</label>
          <Multiselect
            className="bg-white"
            options={accounts}
            selectedValues={selectedValues}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="moneyType"
          />
        </div>
        <div className="date-picker-container">
          <label>Start Date</label>
          <InputDatePicker
            className="fw-normal ms-2"
            value={selectedDayStart}
            onChange={handleDateChangeStart}
            inputPlaceholder={localStorage.getItem('date') === "Persian" ? 'تاریخ را وارد کنید' : 'Enter the date'}
            locale={localStorage.getItem('date') === 'Persian' ? 'fa' : 'en'}
            colorPrimary="#0fbcf9"
            maximumDate={{ year: 9999, month: 12, day: 31 }}
            minimumDate={{ year: 1, month: 1, day: 1 }}
          />
        </div>
        <div className="date-picker-container">
          <label>End Date</label>
          <InputDatePicker
            className="fw-normal ms-2"
            value={selectedDayEnd}
            onChange={handleDateChangeEnd}
            inputPlaceholder={localStorage.getItem('date') === "Persian" ? 'تاریخ را وارد کنید' : 'Enter the date'}
            locale={localStorage.getItem('date') === 'Persian' ? 'fa' : 'en'}
            colorPrimary="#0fbcf9"
            maximumDate={{ year: 9999, month: 12, day: 31 }}
            minimumDate={{ year: 1, month: 1, day: 1 }}
          />
        </div>
        <button
          onClick={() => Submit()}
          type="button"
          className="btn btn-success p-1  mt-3 m-4"
          style={{width:'8rem'}}
        >
          ADD
        </button>
      </div>
    </div>
    <div id="table-to-print" className="card card-body m-auto" style={{ borderTop: "5px solid #4a5cf2" }}>
      <h1 className="text-center rounded m-0 mb-2 p-4 text-light fw-bold bg-info headerWidth">
        Report Cache Customer
      </h1>
      <div className="ps-2 d-flex flex-column flex-sm-row justify-content-between">
        <div className="mt-3">
          <div className="m-2">
            <pre className="fw-bold fs-5">Company Name: {settings[0].company_name} </pre>
            <pre className="fw-bold fs-5">Address: {settings[0].address} </pre>
            <pre className="fw-bold fs-5">Phone: {settings[0].phone}</pre>
          </div>
        </div>
        <div className="mt-4">
          <pre className="fw-bold fs-5">Email: {settings[0].email}</pre>
          <p className="fs-5">Description: {settings[0].description}</p>
        </div>
        <div className="me-2" style={{ width: "150px", height: "150px" }}>
          <img
            className="td_img rounded"
            src={settings[0].company_pic}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              outline: '2px solid aliceblue'
            }}
            alt="Profile"
          />
        </div>
      </div>
      <div className="d-flex m-2">
        <pre className="fs-5 m-1 fw-bold">Customer: {Customer && Customer.name} From {Date_Start && date(Date_Start)} To {Date_End && date(Date_End)}</pre>
      </div><table className="table-custom table-custom-light table-custom-striped table-custom-hover ">
  <thead>
    <tr>
      <th scope="col" className="">No</th>
      <th scope="col" className="">Description</th>
      <th scope="col" className="">Date</th>
      <th scope="col" className="">Currency</th>
      <th scope="col" className="">Deposit</th>
      <th scope="col" className="">Withdraw</th>
      <th scope="col" className="">Balance</th>
    </tr>
  </thead>
  <tbody className="table-group-divider">
    {Records &&
      Records.map((row, index) => {
        if (row.account.id !== previousAccountId) {
          currentBalance = Belance.find((account) => account.id === row.account_id).balance ||0;
          previousAccountId = row.account.id;
          No = 1;
          accountTotalDeposit = 0;
          accountTotalWithdraw = 0;
        } else {
          No += 1;
        }
        if (row.type === "deposite") {
          currentBalance += parseInt(row.amount);
          accountTotalDeposit += parseInt(row.amount);
        } else if (row.type === "withdraw") {
          currentBalance -= parseInt(row.amount);
          accountTotalWithdraw += parseInt(row.amount);
        }
        const isLastTransactionForAccount =
          index === Records.length - 1 || Records[index + 1].account_id !== row.account_id;

        return (
          <React.Fragment key={index}>
            <tr className={`${No % 2 !== 0 && 'striped'} `}>
              <th scope="row" className="">{No}</th>
              <td className="">{row.description}</td>
              <td className="">{date(row.date_created)}</td>
              <td className="">{row.account.type.name}</td>
              <td style={{ color: 'green' }} className="">{row.type === "deposite" ? row.amount : "0"}</td>
              <td style={{ color: 'red' }} className="">{row.type === "withdraw" ? row.amount : "0"}</td>
              <td className="">{currentBalance}</td>
            </tr>
            {isLastTransactionForAccount && (
              <tr className="table-summary " style={{ backgroundColor: "#f0f0f5", fontWeight: "bold" }}>
                <td colSpan="4">
                  <strong className="">Total for Account {row.account.type.name}</strong>
                </td>
                <td style={{ color: 'green' }}>
                  <strong className="">{accountTotalDeposit}</strong>
                </td>
                <td style={{ color: 'red' }}>
                  <strong className="">{accountTotalWithdraw}</strong>
                </td>
                <td>
                  <strong className="">{currentBalance}</strong>
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
  </tbody>
</table>

    </div>
  </div>
</div>
  );
}
