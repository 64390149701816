import axios from "axios";
import DataTable from "react-data-table-component";
import React, { useDebugValue, useEffect, useState } from "react";
import Source from "../../Source";
import AddStock from "./forms/AddStock";
import Swal from "sweetalert2";
import moment from "moment-jalaali";
import Trash from "../../assets/icon/trash.png";
import pencil from "../../assets/icon/pencil.png";


export default function Stock() {
  const [records, setRecords] = useState([]);
  const [item,setitem]=useState([]);
  const [AddStockModal,setAddStockModal]=useState(false);
  const [EditStockModal,setEditStockModal]=useState(false);
  // setAddStockModal(true);
  // setEditStockModal(false);
//   dateInsert
// : 
// "2024-08-21"
// description
// : 
// ""
// id
// : 
// 1
// isdelete
// : 
// false
// item
// : 
// 1
// item_name
// : 
// "dell"
// item_type_name
// : 
// "computer"
// purchase_price
// : 
// 1000
// qty
// : 
// 10
// rate
// : 
// 100
// sell_price
// : 
// 2000
// user
// : 
// 2
// user_name
// : 
// "ahmad"
// weight
// : 
// 10

const handlePerRowsChange = async (newPerPage, page) => {
  setPerPage(newPerPage);
  setCurrentPage(page);
};
// const [data, setData] = useState([]);
const [totalRows, setTotalRows] = useState(0); // تعداد کل ردیف‌ها
const [loading, setLoading] = useState(false);
const [currentPage, setCurrentPage] = useState(1);  // صفحه فعلی
const [perPage, setPerPage] = useState(10); // تعداد آیتم‌ها در هر صفحه

const handlePageChange = page => {
  setCurrentPage(page); // برو به صفحه جدید
};

const fetchData = async (page, pageSize) => {
  setLoading(true);
  try {
    const response = await axios.get(Source.getAddress() + "/api/item",
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('access')}`,
      },
      params: {
        page: page,
        perPage: pageSize,
        delete:0,
        // type:"deposite"
      },
    },
    );
    console.log(response);
    setRecords(response.data.data.data); // داده‌های صفحه جاری
    setTotalRows(response.data.data.total); // تعداد کل ردیف‌ها
    setLoading(false);
  } catch (error) {
    console.error('Error fetching data', error);
    setLoading(false);
  }
};
useEffect(()=>{
  const fetchItem = async () => {
    const token = localStorage.getItem('access');
    if (!token) return;
    const fetchWithToken = async (token) => {
      try {
        const response = await axios.get(Source.getAddress()+'/api/item', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          params:{
            stock:true
          }
        });
        // console.log(response.data);
        setRecords(response.data);
      } catch (error) {
        console.log(error);
        if (error.response && error.response.status === 401) {
          const refreshToken = localStorage.getItem('refresh');
          if (!refreshToken) return;

          try {
            const refreshResponse = await axios.post(Source.getAddress() + '/api/token/refresh', {
              refresh: refreshToken,
            });
            const newAccessToken = refreshResponse.data.access;
            localStorage.setItem('access', newAccessToken);
            await fetchWithToken(newAccessToken);
          } catch (refreshError) {
            console.error('Error refreshing access token:', refreshError);
          }
        } else {
          console.error('Error fetching accounts:', error);
        }
      }
    };

    await fetchWithToken(token);
  };
  fetchItem();
},[])
// useEffect(() => {
//   fetchData(currentPage, perPage);
// }, [currentPage, perPage]);
const date = (d) => {

  const date = new Date(d);

  let formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  let aa = ampm;
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;
  if (localStorage.getItem('date') === "Persian") {
    moment.locale('fa'); // تنظیم لوکال به فارسی
    aa = hours >= 12 ? "ب.ظ" : "ق.ظ";
    formattedDate = moment(d).format('jYYYY-jMM-jDD');
  } else {
    moment.locale('en'); // تنظیم لوکال به انگلیسی
  }

  return `${formattedDate} ${formattedHours}:${formattedMinutes} ${aa}`;
};
  const columns = [
    {
      name: "Id",
      selector: (row) => row.id,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Photo",
      cell: (row) => (
        <img
          src={row.type.picture}
          alt={row.name}
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Item Type",
      selector: (row) => row.type.name,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      style: {
        width: "1px",
        minWidth: "1px",
      },
    },
    {
      name: (
        <strong
          style={{ minWidth: "200px", maxWidth: "240px", width: "240px" }}
        >
          Date Created
        </strong>
      ),
      selector: (row) => date(row.date_creation),
      style: {
        minWidth: "170px!important", // Adjust this value as needed
        maxWidth: "170px", // Adjust this value as needed
        margin:'0px'
        // Width: '600px',    // Set a specific width
      },
      // sortable: true,
    },
    { name: "Description", selector: (row) => row.description, sortable: true },
    { name: "Purchase Price", selector: (row) => row.purchase_price, sortable: true },
    { name: "Sell Price", selector: (row) => row.sell_price, sortable: true },
    { name: "Qty", selector: (row) => row.qty, sortable: true },
    {
      name: <p className="mt-3">Serial Number</p>,
      selector: (row) => row.serial_number,
      sortable: true,
      style: {
        width: "100px",
        minWidth: "100px",
      },
    },
    { name: (<strong
      style={{
        textAlign: "center",
        backgroundColor: "tranceparent",
        width: "100%",
      }}
    >
      Edit
    </strong>),selector: (row) => (
      <button
        onClick={() => {
          // Edit_item(row);
          // setEdit(row);
          // setUpdate(true);
          // seTtitle('Edit Type Money');
        }}
        style={{
          border: "none",
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <img
          height={"25%"}
          width={"25%"}
          src={pencil}
          style={{ backgroundColor: "tranceparent" }}
        />
      </button>
    ) },
    { name: (<strong
      style={{
        textAlign: "center",
        backgroundColor: "tranceparent",
        width: "100%",
      }}
    >
      Delete
    </strong>),   selector: (row) => (
      <button
        onClick={() => {
          // delete_item(row.id);
          // setEdit(row);
          // setUpdate(true);
          // seTtitle('Edit Type Money');
        }}
        style={{
          border: "none",
          backgroundColor: "transparent",
          height: "100%",
        }}
      >
        <img
          height={"20%"}
          width={"20%"}
          src={Trash}
          style={{ backgroundColor: "tranceparent" }}
        />
      </button>
    ) },
    { name: "Add By", selector: (row) => row.user.name, sortable: true },

  ];
  const getItemtype=()=>{
    axios.get(Source.getAddress()+'/api/item', {headers: {
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    }},)
    .then((res)=>{
      setitem(res.data);
    }).catch((err)=>{
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something went wrong!",
        showConfirmButton: false,
        timer: 1000,
      });
    });
  }
  return (
    <div className={`w-100 ${"iransans"}`}>
      <div className="m-auto mt-5 m-5" style={{ height: "100%" ,width:"99%"}}>
        <button
          type="submit"
          className="btn btn-info mb-1 p-1"
          style={{ width: "100px" }}
          onClick={() => {
            getItemtype();
            setAddStockModal(true);
            setEditStockModal(false);
          }}
        
        >
          Add
        </button>
        <div
          className="bg-light d-flex justify-content-lg-between"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <h2 className="m-2 fw-bold mb-4">Stock</h2>
            <select
              id=""
              name="gender"
              //   onChange={ChangeUser}
              class="form-select h-50 m-auto"
              style={{ width: "20%" }}
              //   value={select_user}
            >
              <option value={"all users"} selected>
                All Users
              </option>
              {/* {user.map((item) =>(
              <option value={item.name} selected>{item.name}</option>
              ))} */}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            // onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
          // title="Customers"
          columns={columns}
          data={records}
          // onRowClicked={handleRowClick}
          progressPending={loading}
          striped
            responsive
            highlightOnHover
          pagination
          paginationServer // فعال‌سازی صفحه‌بندی سرور ساید
          paginationTotalRows={totalRows} // تعداد کل ردیف‌ها
          onChangePage={handlePageChange} // تغییر صفحه
          onChangeRowsPerPage={handlePerRowsChange} // تغییر تعداد ردیف‌ها در صفحه    
          customStyles={{
            headCells: {
              style: {
                fontWeight:'bolder',
                display: 'flex',
                minWidth: '10px', // تنظیم اندازه عنوان‌ها
                maxWidth: '10px',
                width: '10px',
                margin: '0px',
              },
            },
            cells: {
              style: {
                fontWeight:'bold',
                minWidth: '10px', // تنظیم اندازه داده‌ها
                maxWidth: '10px',
                width: '15px',
              },
            },
          }}
        />
    <AddStock
              records={records}
              setRecords={setRecords}
              Item={item}
              AddItemModal={AddStockModal}
              close={()=>setAddStockModal(false)}
    />
      </div>
    </div>
  );
}
