import React, { useState, useEffect, useMemo ,useRef} from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import Edit_account from "./forms/Edit_account.js";
import Add from "../assets/icon/add.png";
import Belance from "./forms/Belance";
import Add_belance from "./Add_belance.js";
import AddAccount from "../pages/AddAccount.js";
import Trash from "../assets/icon/trash.png";
import Source from "../Source";
import Swal from "sweetalert2";
import { gregorianToJalali } from "shamsi-date-converter";
// import { fa } from "jalaali-react-date-picker/lib/core/constants/translations.js";

const formatNumber = (number) => {
  return number.toLocaleString(); // Formats number with thousand separators
};

export default function Account({
  user,
  settings,

}) {
  const [money,setmoney]=useState([]);
  const [customers,setcustomers]=useState([]);
  const [accounts,setAccounts]=useState([]);
  useEffect(() => {
    axios.get(Source.getAddress() + '/api/customers', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      setcustomers(res.data);;
    }).catch((err)=>{
      console.log(err);
    });

  },[]);
  useEffect(()=>{
    axios.get(Source.getAddress() + '/api/money', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access')}`,
      },params: {
        delete: 'False',
      },
    }).then((res)=>{
      console.log(res);
      setmoney(res.data);
    }).catch((err)=>{
      console.log(err);
    });
  },[]);
 useEffect(()=>{
  axios.get(Source.getAddress() + '/api/belance', {
    headers: {
      'Authorization': `Bearer ${localStorage.getItem('access')}`,
    },params: {
      delete: 'False',
    },
  }).then((res)=>{
    setAccounts(res.data);
    setRecords(res.data);
  }).catch((err)=>{
    console.log(err);
  });
 },[]);
  const [records, setRecords] = useState([]);
  const [belance, setBelance] = useState([]);
  const [openBelance, setOpenBelance] = useState(false);
  const [editAccount, setEditAccount] = useState(null);
  const [id, setId] = useState("");
  const [addAccountModal, setAddAccountModal] = useState(false);
  const [editAccountModal, setEditAccountModal] = useState(false);
  const [isBelanceEmpty, setIsBelanceEmpty] = useState(false);
  const [checkBelanceResult, setCheckBelanceResult] = useState({});
  const [selectedOption, setSelectedOption] = useState();
  const [nmoney, setnmoney] = useState(null);
  const [smoney, setSmeony] = useState([]);
  const [editbelance, setEditbelance] = useState({
    account: null,
    type: null,
    belance: 0,
    date_created: "",
    user: null,
    isdelete: false,
  });
  const [add, setAdd] = useState({
    id: 0,
    account: 0,
    type: 0,
    account_name: "",
    belance: 0,
    date_created: "",
    moneyType: "",
    account_name: "",
    moneyId: 0,
    user: 0,
    user_name: "",
    isdelete: false,
  });
  const [editFull, setEditFull] = useState({});

  const res = useMemo(() => window.innerWidth > 768, []);

  // useEffect(() => {
  //   setRecords(accounts);
  // }, [accounts]);

  const handleRowClick = (row) => {
    if (!checkBelanceResult[row.id]) {
      setSelectedOption({ name: row.account_name, account: row.account });
      setnmoney({ name: row.moneyType, moneyid: row.moneyid });
      setEditFull(row);
      setEditbelance({
        ...editbelance,
        account: row.account,
        type: row.type,
        belance: row.belance,
        date_created: row.date_created,
        user: row.user,
        isdelete: row.isdelete,
      });
      setEditAccountModal(true);
      const find = records.filter((p) => p.account === row.account);
      let moneys = [];
      for (let i = 0; i < money.length; i++) {
        const res = find.find((r) => r.type === money[i].id);
        if (!res) {
          moneys.push(money[i]);
        }
      }
      moneys.push({ name: row.moneyType, moneyid: row.moneyid });
      setSmeony(moneys);
    }
  };

  const date = (d) => {
    const date = new Date(d);
    let formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    if (settings.length === 0) {
      let settings1 = { language: "English" };
      axios
        .post(Source.getAddress() + "/api/settings", settings1)
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    if (settings[0].date === "Persian") {
      formattedDate = convertToHijriShamsi(
        `${formattedDate}  ${formattedHours}:${formattedMinutes} ${ampm}`
      );
    }
    return `${formattedDate}  ${formattedHours}:${formattedMinutes} ${ampm}`;
  };

  const handleViewBelance = (row) => {
    const filteredBelance = belance.filter((item) => item.account === row.id);
    setEditAccount(filteredBelance);
    setId(row.id);
    setOpenBelance(true);
  };

  const handleFilter = (e) => {
    const newData = accounts.filter((row) => {
      if (typeof row.account_name === "string") {
        return (
          row.account_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          row.moneyType.toLowerCase().includes(e.target.value.toLowerCase())
        );
      }
      return false;
    });
    setRecords(newData);
  };

  const convertToHijriShamsi = (gregorianDate) => {
    const [jalaliYear, jalaliMonth, jalaliDay] = gregorianToJalali(
      new Date(gregorianDate)
    );
    return `${jalaliYear}/${jalaliMonth}/${jalaliDay}`;
  };

  const columnsDesktop = [
    { name: <strong>Account ID</strong>, selector: (row) => row.id },
    {
      name: <strong>Customer Name</strong>,
      selector: (row) => row.account_name,
    },
    {
      name: <strong>Date Created</strong>,
      selector: (row) => date(row.date_created),
    },
    { name: <strong>Money Type</strong>, selector: (row) => row.moneyType },
    {
      name: <strong>Amount</strong>,
      selector: (row) => row.belance,
    },
    { name: <strong>Added by</strong>, selector: (row) => row.user_name },
    {
      name: (
        <strong
          style={{
            textAlign: "center",
            backgroundColor: "transparent",
            width: "100%",
          }}
        >
          Delete
        </strong>
      ),
      selector: (row) => (
        <button
          className={`${row.ontransaction ? "d-none" : ""}`}
          onClick={() => delete_report(row)}
          style={{
            border: "none",
            backgroundColor: "transparent",
            height: "100%",
          }}
        >
          <img
            height={"15%"}
            width={"15%"}
            src={Trash}
            style={{ backgroundColor: "transparent" }}
          />
        </button>
      ),
    },
  ];

  const columnsTablet = [
    {
      name: "account_name",
      selector: (row) => row.account_name,
      sortable: true,
    },
    {
      name: "Date Created",
      selector: (row) => row.date_created,
      sortable: true,
    },
    { name: "moneyType", selector: (row) => row.moneyType, sortable: true },
    { name: "belance", selector: (row) => row.belance, sortable: true },
  ];

  const [select_user, setselect_user] = useState("");

  const ChangeUser = (e) => {
    if (e.target.value !== "all users") {
      const newData = accounts.filter((row) => {
        if (typeof row.user_name === "string") {
          return row.user_name
            .toLowerCase()
            .includes(e.target.value.toLowerCase());
        }
        return false;
      });
      setRecords(newData);
    } else {
      setRecords(accounts);
    }
    setselect_user(e.target.value);
  };

  const delete_report = async (row) => {
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});
const result = await swalWithBootstrapButtons.fire({
  title: "Are you sure?",
  text: "You won't be able to revert this!",
  icon: "warning",
  showCancelButton: true,
  confirmButtonText: "Yes, delete it!",
  cancelButtonText: "No, cancel!",
  reverseButtons: true,
});
if (result.isConfirmed) {
  let belance_delete = {
    account: row.account,
    type: row.type,
    belance: row.belance,
    date_created: row.date_created,
    user: row.user,
    isdelete: "True",
  };
  row.isdelete = "True";
  axios
    .put(
      Source.getAddress() + "/api/belance" + `${row.id}/`,
      belance_delete,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access")}`,
        }, // Add the access token here
        // Do NOT set 'Content-Type' when using FormData; let the browser set it automatically
      }
    )
    .then((res) => {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Updated successfully !",
        showConfirmButton: false,
        timer: 1000,
      });
      setRecords(records.filter((a) => a.id !== row.id));
    })
    .catch((err) => {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Something went wrong !",
        showConfirmButton: false,
        timer: 1000,
      });
    });

} else if (result.dismiss === Swal.DismissReason.cancel) {
  swalWithBootstrapButtons.fire({
    title: "Cancelled",
    text: "Your imaginary file is safe :)",
    icon: "error",
  });
} 

  };
  const [AddcustomerModal,setAddcustomerModal]=useState(false);
  const inputRef=useRef(null);
  useEffect(()=>{
    inputRef.current.select();
  },[addAccountModal])
  return (
    <div
      className="w-100 h-100"
      onClick={(e) => {
        if (e.target.className === "w-100 h-100") {
          setAddAccountModal(false);
        }
      }}
    >
      <div className="container mt-5 p-0">
        <button
          onClick={() => setAddAccountModal(true)}
          type="submit"
          className="btn btn-info mb-1 p-1"
          style={{ width: "100px", color: "white" }}
        >
          Add
        </button>
        <div
          className="bg-light d-flex justify-content-lg-between"
          style={{ borderTop: "5px solid #4a5cf2" }}
        >
          <h2 className="m-2 fw-bold mb-4">Accounts</h2>
          <div className="d-flex w-100 h-100 m-auto justify-content-between">
            <select
              id=""
              name="gender"
              onChange={ChangeUser}
              className="form-select h-50 m-auto"
              style={{ width: "20%" }}
              value={select_user}
            >
              <option value={"all users"}>All Users</option>
              {user.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <input
            className="form-control m-2 mb-4"
            style={{ width: "20%" }}
            onChange={handleFilter}
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
        </div>

        <DataTable
          columns={res ? columnsDesktop : columnsTablet}
          data={records}
          // onRowClicked={handleRowClick}
          striped
          responsive
          highlightOnHover
          pagination
        />
        {openBelance && (
          <Belance
            close={() => setOpenBelance(false)}
            id={id}
            accountbelance={editAccount}
          />
        )}
        <Add_belance
          // customers={customers}
          accounts={accounts}
          setAccounts={setAccounts}
          close={() => setAddAccountModal(false)}
          addAccountModal={addAccountModal}
          records={records}
          setRecords={setRecords}
          money={money}
          settings={settings}
          setAddcustomerModal={setAddcustomerModal}
        />
        <Edit_account
          smoney={smoney}
          setsmeony={setSmeony}
          add={add}
          setadd={setAdd}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          nmoney={nmoney}
          setnmoney={setnmoney}
          Editefull={editFull}
          setEditeFull={setEditFull}
          bbelance={editbelance}
          setbbelance={setEditbelance}
          customers={customers}
          accounts={accounts}
          setAccounts={setAccounts}
          close={() => setEditAccountModal(false)}
          addAccountModal={editAccountModal}
          records={records}
          setRecords={setRecords}
          money={money}
          settings={settings}
        />
        <AddAccount
            // loading={loading}
            // setLoading={setLoading}
            inputRef={inputRef}
            close={() => setAddcustomerModal(false)}
            addAccountModal={AddcustomerModal}
            records={records}
            setRecords={setRecords}
          />
      </div>
    </div>
  );
}
